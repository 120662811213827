import { createRouter, createWebHistory } from 'vue-router';
import DashboardAdvice from '@/views/dashboard-advice.vue';
import DashboardCampaign from '@/views/dashboard-campaign.vue';
import DashboardTrends from '@/views/dashboard-trends.vue';
import LandingPage from '@/components/LandingPage.vue';
import DashboardUser from '@/views/dashboard-user.vue';
import DashboardVision from '@/views/dashboard-vision.vue';
import DashboardVoice from '@/views/dashboard-voice.vue';
import UserLogin from '@/components/UserLogin.vue';
import UserSignup from '@/components/UserSignup.vue';
import ForgotPassword from '@/components/ForgotPassword.vue';
import EarlyAccess from '@/views/EarlyAccess.vue';
import ThankYou from '@/views/ThankYou.vue';
import AdminWorkflows from '@/views/admin-workflows.vue';
import api from '@/config/api';
import axios from 'axios';

const routes = [
  { 
    path: '//dashboard-advice', 
    name: 'DashboardAdvice', 
    component: DashboardAdvice,
    meta: { requiresAuth: true }
  },
  { 
    path: '//dashboard-campaign', 
    name: 'DashboardCampaign', 
    component: DashboardCampaign,
    meta: { requiresAuth: true }
  },
  { 
    path: '/dashboard-trends', 
    name: 'DashboardTrends', 
    component: DashboardTrends,
    meta: { requiresAuth: false }  // Temporarily set to false for testing
  },
  { 
    path: '/dashboard-user', 
    name: 'DashboardUser', 
    component: DashboardUser,
    props: route => ({ url: route.query.url }),
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'EarlyAccess',
    component: EarlyAccess
  },
  { 
    path: '/app', 
    name: 'LandingPage', 
    component: LandingPage,
    beforeEnter: (to, from, next) => {
      // Check if user is coming from dashboard with analysis complete
      if (from.name === 'DashboardUser' && to.query.analysisComplete) {
        next({ name: 'DashboardUser', query: { url: to.query.url } });
      } else {
        // Allow access to landing page even if logged in
        next();
      }
    }
  },
  { 
    path: '/thank-you', 
    name: 'ThankYou', 
    component: ThankYou,
     },
  { 
    path: '//dashboard-vision', 
    name: 'DashboardVision', 
    component: DashboardVision,
    meta: { requiresAuth: true }
  },
  { 
    path: '//dashboard-voice', 
    name: 'DashboardVoice', 
    component: DashboardVoice,
    meta: { requiresAuth: true }
  },
  { 
    path: '/user-login', 
    name: 'UserLogin', 
    component: UserLogin,
    beforeEnter: (to, from, next) => {
      // If user is already logged in, redirect to dashboard
      const token = localStorage.getItem('userToken');
      if (token) {
        // Check if token is valid by making an API call
        axios.get(`${api.baseURL}/app/validate-token`, {
          headers: { 'Authorization': `Bearer ${token}` }
        }).then(() => {
          next({ name: 'DashboardUser' });
        }).catch(() => {
          // Token is invalid, remove it and continue to login
          localStorage.removeItem('userToken');
          localStorage.removeItem('username');
          next();
        });
      } else {
        next();
      }
    }
  },
  { 
    path: '/user-signup', 
    name: 'UserSignup', 
    component: UserSignup,
    beforeEnter: (to, from, next) => {
      // If user is already logged in, redirect to dashboard
      const token = localStorage.getItem('userToken');
      if (token) {
        next({ name: 'DashboardUser' });
      } else {
        next();
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/admin-workflows',
    name: 'AdminWorkflows',
    component: AdminWorkflows,
    meta: { 
      requiresAuth: false,
      requiresAdmin: false 
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Navigation guard for protected routes
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('userToken');
  
  // Check if route requires auth
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next('/user-login');
      return;
    }

    // Check if route requires admin role
    if (to.matched.some(record => record.meta.requiresAdmin)) {
      try {
        const response = await axios.get('/app/user-details');
        if (response.data.role === 'admin') {
          next();
        } else {
          next('/dashboard-user');
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        next('/user-login');
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
