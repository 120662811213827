<template>
  <div id="dashboard">
    <!-- Loading overlay -->
    <div v-if="isSubmitting" class="loading-overlay">
      <div class="loading-content">
        <LoadingWheel 
          :status="loadingMessage" 
          :progress="taskProgress" 
        />
      </div>
    </div>

    <!-- Token expiration warning -->
    <div v-if="showTokenWarning" class="token-warning">
      <div class="token-warning-content">
        <p>Your session will expire in {{ Math.floor(timeUntilExpiration / 60) }} minutes and {{ timeUntilExpiration % 60 }} seconds.</p>
        <div class="token-warning-actions">
          <button @click="refreshToken" class="refresh-token-btn">Refresh Session</button>
          <button @click="handleLogout" class="logout-btn">Logout</button>
        </div>
      </div>
    </div>

    <!-- Sidebar -->
    <SideBar />

    <!-- Header Section -->
  <header class="header" :class="{ 'hidden': !isHeaderVisible }">
      <div class="header-top">
        <h1>CP3 Dashboard 
          <span v-if="user" class="analyzing-url">
            <span v-if="user.username">{{ user.username }}</span>
          </span>
          <span v-else-if="loading" class="loading-text">Loading user details...</span>
        </h1>
        
        <!-- URL submission form in its own row -->
        <div class="url-analysis-row">
          <div class="url-submit-container">
            <form @submit.prevent="handleUrlSubmit" class="url-form">
              <span class="url-prefix">https://</span>
              <input 
                type="text"
                v-model="newUrl" 
                placeholder="Analyze URL"
                required
                pattern="^[^/\s]+\.[^/\s]+.*$"
                class="url-input"
              >
              <button 
                type="submit" 
                :disabled="isSubmitting" 
                class="arrow-button"
              >
                <span v-if="isSubmitting" class="spinner"></span>
                <span v-else>➔</span>
              </button>
            </form>
            <div v-if="user && user.url" class="current-url">
              Currently analyzing: {{ formatUrl(user.url) }}
            </div>
          </div>
        </div>
      </div>
      <div class="user-info">
        <img 
          :src="getProfilePicture" 
          :alt="user?.instagram_handle + ' profile picture'" 
          @error="handleProfilePicError"
          class="profile-picture"
        />
        <button @click="handleLogout" class="logout-btn">Logout</button>
      </div>
    </header>

    <!-- Section Toggle Button -->
    <button 
      class="section-toggle"
      @click="nextSection"
    >
      {{ sectionsLong[currentSection] }} (Click to Toggle)
    </button>

    <!-- Main Content -->
    <main class="main-content">
      <div class="charts-container">
        <!-- Chart Sections -->
        <div class="chart-sections-wrapper">
          <!-- Tone Analysis -->
          <div class="chart-section" :class="{ active: currentSection === 0 }">
            <h2>Tone Analysis</h2>
            <div v-if="chartStates.tone.loading" class="loading">Loading tone analysis...</div>
            <div v-else-if="chartStates.tone.error" class="error-message">
              {{ chartStates.tone.error }}
            </div>
            <div v-else-if="data.tone_analysis" class="chart-container">
              <RadarChart 
                v-if="toneChartData"
                :key="`tone-${JSON.stringify(toneChartData)}`"
                :chartData="toneChartData"
                :options="toneChartOptions"
                class="chart"
              />
              <div v-if="data.tone_analysis?.data?.description" class="analysis-description">
                {{ data.tone_analysis.data.description }}
              </div>
              <div v-else class="no-data">
                Unable to display description
              </div>
            </div>
          </div>

          <!-- Emotion Analysis -->
          <div class="chart-section" :class="{ active: currentSection === 1 }">
            <h2>Emotion Analysis</h2>
            <div v-if="chartStates.emotion.loading" class="loading">Loading emotion analysis...</div>
            <div v-else-if="chartStates.emotion.error" class="error-message">
              {{ chartStates.emotion.error }}
            </div>
            <div v-else-if="data.emotion_analysis" class="chart-container">
              <DoughnutChart 
                v-if="emotionChartData"
                :key="`emotion-${JSON.stringify(emotionChartData)}`"
                :chartData="emotionChartData"
                :options="emotionChartOptions"
              />
              <div v-if="data.emotion_analysis?.data?.description" class="analysis-description">
                {{ data.emotion_analysis.data.description }}
              </div>
              <div v-else class="no-data">
                Unable to display description
              </div>
            </div>
          </div>

          <!-- Product Word Analysis -->
          <div class="chart-section" :class="{ active: currentSection === 2 }">
            <div class="split-column">
              <div class="cp3-score-section">
                <h3>CP3 Score</h3>
                <div class="score-display">
                  {{ calculateOverallScore() }}/10
                </div>
              </div>
              <h2>Product Word Analysis</h2>
              <div class="product-word-section">
                <div v-if="chartStates.productWord.loading" class="loading">Loading product word analysis...</div>
                <div v-else-if="chartStates.productWord.error" class="error-message">
                  {{ chartStates.productWord.error }}
                </div>
                <div v-else-if="data.product_word_analysis" class="chart-container">
                  <PieChart 
                    v-if="productWordChartData"
                    :key="`product-word-${JSON.stringify(productWordChartData)}`"
                    :chartData="productWordChartData"
                    :options="productWordChartOptions"
                  />
                  <div class="word-count-info">
                    Total Words: {{ data.product_word_analysis.word_count }}
                  </div>
                  <div v-if="!productWordChartData" class="no-data">
                    Unable to display product word chart
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Visual Analysis -->
          <div class="chart-section" :class="{ active: currentSection === 3 }">
            <h2>Visual Analysis</h2>
            <div class="analysis-controls">
              <div 
                :class="['analysis-toggle', { active: currentAnalysis === 'object' }]"
                role="button"
                tabindex="0"
                @click.prevent="setAnalysisType('object')"
                @keydown.enter.prevent="setAnalysisType('object')"
              >
                Object Analysis
              </div>
              <div 
                :class="['analysis-toggle', { active: currentAnalysis === 'style' }]"
                role="button"
                tabindex="0"
                @click.prevent="setAnalysisType('style')"
                @keydown.enter.prevent="setAnalysisType('style')"
              >
                Style Analysis
              </div>
              <div 
                :class="['analysis-toggle', { active: currentAnalysis === 'face' }]"
                role="button"
                tabindex="0"
                @click.prevent="setAnalysisType('face')"
                @keydown.enter.prevent="setAnalysisType('face')"
              >
                Face Analysis
              </div>
            </div>

            <div class="carousel-container">
              <div class="carousel-navigation">
                <button @click="previousImage" class="nav-button">&lt;</button>
                <div class="image-container" v-if="currentImage">
                  <img 
                    :src="currentImage.url" 
                    :alt="currentImage.alt"
                    @load="onImageLoad"
                    class="analyzed-image"
                  >
                  <template v-if="currentAnalysis === 'object' && currentImage.object_results">
                    <div v-for="(object, index) in currentImage.object_results" 
                         :key="index" 
                         :style="getMarkerStyle(object)"
                         class="object-marker">
                      {{ object.label }}
                      <div v-if="object.attributes && object.attributes.length" class="object-attributes">
                        {{ object.attributes.join(', ') }}
                      </div>
                    </div>
                  </template>
                  <template v-else-if="currentAnalysis === 'style' && currentImage.style_results">
                    <div class="style-analysis">
                      <div class="style-info">
                        <h3>Style Analysis</h3>
                           <!-- Colors -->
                        <div v-if="currentImage.style_results.colors" class="colors-section">
                          <h4>Colors</h4>
                          <div class="color-chips">
                            <div v-for="(color, index) in currentImage.style_results.colors" 
                                :key="index"
                                class="color-chip"
                                :style="{ backgroundColor: color }">
                              {{ color }}
                            </div>
                          </div>
                        </div>
                        
                        <!-- Dominant Colors -->
                        <div v-if="currentImage.style_results.dominant_colors" class="dominant-colors">
                          <h4>Dominant Colors</h4>
                          <ul>
                            <li v-for="(color, index) in currentImage.style_results.dominant_colors" 
                                :key="index">{{ color }}</li>
                          </ul>
                        </div>
                        
                        <!-- Aesthetic Score -->
                        <div v-if="typeof currentImage.style_results.aesthetic_score !== 'undefined'" class="aesthetic-score">
                          <h4>Aesthetic Score</h4>
                          <span>{{ Number(currentImage.style_results.aesthetic_score).toFixed(2) }}</span>
                        </div>
                        
                        <!-- Style Attributes -->
                        <div v-if="currentImage.style_results.style_attributes" class="style-attributes">
                          <h4>Style Attributes</h4>
                          <ul>
                            <li>Image Type: {{ currentImage.style_results.style_attributes.image_type || 'Unknown' }}</li>
                            <li>Lighting: {{ currentImage.style_results.style_attributes.lighting || 'Unknown' }}</li>
                            <li>Color Tone: {{ currentImage.style_results.style_attributes.color_tone || 'Unknown' }}</li>
                            <li>Mood: {{ currentImage.style_results.style_attributes.mood || 'Unknown' }}</li>
                            <li>Composition: {{ currentImage.style_results.style_attributes.composition || 'Unknown' }}</li>
                          </ul>
                        </div>
                        
                        <!-- Image Quality -->
                        <div v-if="currentImage.style_results.image_quality" class="image-quality">
                          <h4>Image Quality</h4>
                          <ul>
                            <li>Sharpness: {{ currentImage.style_results.image_quality.sharpness || 'Unknown' }}</li>
                            <li>Contrast: {{ currentImage.style_results.image_quality.contrast || 'Unknown' }}</li>
                            <li>Noise Level: {{ currentImage.style_results.image_quality.noise_level || 'Unknown' }}</li>
                            <li>Exposure: {{ currentImage.style_results.image_quality.exposure || 'Unknown' }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="currentAnalysis === 'face' && currentImage.face_results">
                    <div v-for="(face, index) in currentImage.face_results" 
                         :key="index" 
                         :style="getFaceMarkerStyle(face)"
                         class="face-marker">
                      <div class="face-attributes">
                        <span>Age: {{ face.attributes?.age_range || 'Unknown' }}</span>
                        <span>Gender: {{ face.attributes?.gender || 'Unknown' }}</span>
                        <span>Expression: {{ face.attributes?.expression || 'Neutral' }}</span>
                      </div>
                    </div>
                  </template>
                </div>
                <button @click="nextImage" class="nav-button">&gt;</button>
              </div>
              <div class="carousel-dots">
                <span 
                  v-for="(_, index) in images" 
                  :key="index"
                  :class="['dot', { active: currentImageIndex === index }]"
                  @click="setCurrentImage(index)"
                ></span>
              </div>
            </div>
          </div>
          
          <!-- Instagram Comparison Section -->
          <div class="chart-section comparison-section" :class="{ active: currentSection === 6 }">
            <h2>Instagram Performance Comparison</h2>
            <div class="comparison-grid">
              <div class="comparison-chart wide-chart">
                <h3>Engagement Rate Over Time</h3>
                <LineChart 
                  v-if="instagramComparisonData.engagement"
                  :chartData="instagramComparisonData.engagement"
                  :options="lineChartOptions"
                />
              </div>
              <div class="comparison-chart wide-chart">
                <h3>Followers Over Time</h3>
                <LineChart 
                  v-if="instagramComparisonData.followers"
                  :chartData="instagramComparisonData.followers"
                  :options="lineChartOptions"
                />
              </div>
              <div v-if="instagramComparisonData.likes" class="chart-container">
                <h3>Like Changes Over Time</h3>
                <div style="position: relative; ;">
                  <LineChart 
                    :chartData="instagramComparisonData.likes"
                    :options="lineChartOptions"
                    :key="'likes-chart'"
                  />
                </div>
              </div>                       
              <div class="comparison-chart wide-chart">
                <h3>Quality Score Over Time</h3>
                <LineChart 
                  v-if="instagramComparisonData.quality"
                  :chartData="instagramComparisonData.quality"
                  :options="lineChartOptions"
                />
              </div>
            </div>
          </div>
          </div>
        </div>
      </main>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick } from 'vue';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  Title, 
  Tooltip, 
  Legend, 
  RadialLinearScale, 
  PointElement, 
  LineElement,
  Filler,
  RadarController,
  ArcElement,
  BarController,
  DoughnutController,
  LineController,
  PieController
} from 'chart.js';
import { BarChart, RadarChart, DoughnutChart, LineChart, PieChart, PolarAreaChart } from 'vue-chart-3';
import axios from 'axios';
import apiConfig from '../config/api.js';
import { useRouter, useRoute } from 'vue-router';
import LoadingWheel from '@/components/LoadingWheel.vue';
import SideBar from '@/components/SideBar.vue';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  RadarController,
  PieController,
  ArcElement,
  BarController,
  DoughnutController,
  LineController
);

export default {
  name: 'DashboardUser',
  components: {
    BarChart,
    RadarChart,
    DoughnutChart,
    LineChart,
    PieChart,
    PolarAreaChart,
    LoadingWheel,
    SideBar,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const data = ref({
      tone_analysis: null,
      emotion_analysis: null,
      product_word_analysis: null,
      object_analysis: null,
      style_analysis: null,
      audience_analysis: null
    });
    const error = ref(null);
    const loading = ref(false);
    const lastAnalyzedUrl = ref(null);
    const user = ref(null);
    const currentUrl = ref(null);
    
    // Add new refs for token management
    const tokenExpirationTime = ref(null);
    const tokenRefreshInterval = ref(null);
    const showTokenWarning = ref(false);
    const timeUntilExpiration = ref(null);

    const chartStates = ref({
      tone: { loading: false, error: null },
      emotion: { loading: false, error: null },
      productWord: { loading: false, error: null },
      object: { loading: false, error: null },
      style: { loading: false, error: null },
      audience: { loading: false, error: null },
      instagramComparison: { loading: false, error: null },
      quality: { loading: false, error: null }
    });

    const newUrl = ref('');
    const isSubmitting = ref(false);
    const loadingMessage = ref('');
    const taskProgress = ref(0);
    const pollingInterval = ref(null);

    // Add instagramComparisonData ref with proper initialization
    const instagramComparisonData = ref({
      engagement: {
        labels: [],
        datasets: [
          {
            label: "Engagement Rate",
            data: [],
            borderColor: "#cdeb2e",
            tension: 0.1
          },
          {
            label: "Average Engagement Rate",
            data: [],
            borderColor: "#381428",
            tension: 0.1
          }
        ]
      },
      followers: {
        labels: [],
        datasets: [
          {
            label: "Followers",
            data: [],
            borderColor: "#a4c10d",
            tension: 0.1,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true,
                  max: 1,
                  title: {
                    display: true,
                    text: 'Score'
                  }
                }
              }
            }
          },
          {
            label: "Follower Change",
            data: [],
            borderColor: "#5d384c",
            tension: 0.1
          }
        ]
      },
      likes: {
        labels: [],
        datasets: [{
          label: "Like Change",
          data: [],
          borderColor: "#725263",
          tension: 0.1
        }]
      },
      quality: {
        labels: [],
        datasets: [{
          label: "Quality Score",
          data: [],
          borderColor: "#d8f448",
          tension: 0.1
        }]
      }
    });

    // Add a flag to track if we're already fetching Instagram data
    const isLoadingInstagramComparison = ref(false);

    // Tone Chart Data
    const toneChartData = computed(() => {
      if (!data.value.tone_analysis?.data?.tone) {
        console.log('No tone data available');
        return null;
      }

      const toneData = data.value.tone_analysis.data.tone;
      console.log('Processing tone data:', toneData);

      // The tone values are directly in the tone object
      const processedToneData = {
        formal: toneData.formal || 0,
        informal: toneData.informal || 0,
        direct: toneData.direct || 0,
        indirect: toneData.indirect || 0,
        concise: toneData.concise || 0,
        elaborated: toneData.elaborated || 0,
        technical: toneData.technical || 0,
        simple: toneData.simple || 0,
        persuasive: toneData.persuasive || 0,
        informative: toneData.informative || 0
      };

      const chartData = {
        labels: Object.keys(processedToneData),
        datasets: [{
          label: 'Tone Analysis',
          data: Object.values(processedToneData),
          fill: true,
          backgroundColor: 'rgba(166, 193, 13, 0.5)',
          borderColor: '#cdeb2e',
          pointBackgroundColor: '#cdeb2e',
          pointBorderColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderColor: '#cdeb2e',
          borderWidth: 2
        }]
      };
      console.log('Generated tone chart data:', chartData);
      return chartData;
    });

    // Add a watcher to debug data changes
    watch(() => data.value.tone_analysis, (newVal) => {
      console.log('Tone analysis data changed:', newVal);
      if (newVal?.data) {
        console.log('New chart data will be:', {
          labels: Object.keys(newVal.data),
          values: Object.values(newVal.data)
        });
      }
    }, { deep: true });

    // Emotion Chart Data
    const emotionChartData = computed(() => {
      if (!data.value.emotion_analysis?.data) {
        console.log('No emotion data available');
        return null;
      }

      const emotionData = data.value.emotion_analysis.data;
      const emotionScores = emotionData.emotion_scores;
      console.log('Processing emotion data:', emotionScores);

      // Fixed order of emotions for consistent display
      const fixedEmotions = [
        'Friendly', 'Relaxed', 'Inspiring', 'Enthusiastic', 
        'Optimistic', 'Professional', 'Analytical', 'Cautious', 'Empathetic'
      ];

      // Create arrays for labels and data in fixed order
      const orderedData = fixedEmotions.map(emotion => ({
        emotion,
        score: parseFloat(emotionScores[emotion] || 0)
      }));

      // Generate colors based on scores (darker = higher score)
      const generateColor = (score) => `rgba(93, 56, 76, ${score})`;

      return {
        labels: orderedData.map(d => d.emotion),
        datasets: [{
          label: 'Emotion Analysis',
          data: orderedData.map(d => d.score),
          backgroundColor: orderedData.map(d => generateColor(d.score)),
          borderColor: 'white',
          borderWidth: 2,
          hoverBorderColor: 'white',
          hoverBorderWidth: 3,
        }]
      };
    });

    // Object Chart Data
    const objectChartData = computed(() => {
      if (!data.value.object_analysis?.objects) {
        console.log('No object data available');
        return null;
      }

      const objects = data.value.object_analysis.objects;
      console.log('Processing object data:', objects);

      // Count occurrences of each object type
      const objectCounts = objects.reduce((acc, obj) => {
        obj.objects.forEach(item => {
          const name = item.name || item.label;
          acc[name] = (acc[name] || 0) + 1;
        });
        return acc;
      }, {});

      // Convert to chart data format
      const chartData = {
        labels: Object.keys(objectCounts),
        datasets: [{
          label: 'Object Occurrences',
          data: Object.values(objectCounts),
          backgroundColor: [
            'rgba(205, 235, 46, 0.5)',
            'rgba(56, 20, 40, 0.5)',
            'rgba(164, 193, 13, 0.5)',
            'rgba(93, 56, 76, 0.5)',
            'rgba(216, 244, 72, 0.5)'
          ]
        }]
      };
      console.log('Generated object chart data:', chartData);
      return chartData;
    });

    // Style Chart Data
    const styleChartData = computed(() => {
      if (!data.value.style_analysis?.data) return null;
      const styleData = data.value.style_analysis.data;
      return {
        labels: Object.keys(styleData),
        datasets: [{
          label: 'Style Analysis',
          data: Object.values(styleData),
          backgroundColor: [
            'rgba(114, 82, 99, 0.5)',
            'rgba(164, 193, 13, 0.5)',
            'rgba(38, 15, 27, 0.5)',
            'rgba(216, 244, 72, 0.5)',
            'rgba(93, 56, 76, 0.5)'
          ]
        }]
      };
    });

    // Audience Chart Data
    const audienceChartData = computed(() => {
      if (!data.value.audience_analysis?.data) return null;
      const audienceData = data.value.audience_analysis.data;
      return {
        labels: Object.keys(audienceData),
        datasets: [{
          label: 'Audience Analysis',
          data: Object.values(audienceData),
          backgroundColor: [
            'rgba(205, 235, 46, 0.5)',
            'rgba(56, 20, 40, 0.5)',
            'rgba(183, 199, 93, 0.5)',
            'rgba(93, 56, 76, 0.5)',
            'rgba(164, 193, 13, 0.5)'
          ]
        }]
      };
    });

    // Chart Options
    const toneChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        r: {
          beginAtZero: true,
          min: 0,
          max: 1,
          ticks: {
            stepSize: 0.01
          },
          grid: {
            circular: true
          },
          pointLabels: {
            font: {
              size: 12
            }
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'top'
        }
      }
    };

    const emotionChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: '50%',
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            generateLabels: (chart) => {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map((label, i) => ({
                  text: `${label}: ${(data.datasets[0].data[i] * 100).toFixed(0)}%`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  strokeStyle: data.datasets[0].borderColor,
                  lineWidth: data.datasets[0].borderWidth,
                  hidden: false,
                  index: i
                }));
              }
              return [];
            }
          }
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.label || '';
              const value = context.raw || 0;
              return `${label}: ${(value * 100).toFixed(0)}%`;
            }
          }
        }
      }
    };

    const defaultBarOptions = {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
          max: 1,
          title: {
            display: true,
            text: 'Score'
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    };

    // Error handling for charts
    const handleChartError = (chartType, error) => {
      console.error(`Error rendering ${chartType} chart:`, error);
      console.error('Chart data at time of error:', data.value[`${chartType}_analysis`]);
      chartStates.value[chartType].error = `Error displaying ${chartType} chart: ${error.message}`;
    };

    // Initialize charts using composition API
    const { radarChartProps: toneChartProps } = {
      chartData: toneChartData,
      options: toneChartOptions
    };

    const { barChartProps: emotionChartProps } = {
      chartData: emotionChartData,
      options: emotionChartOptions
    };

    const { barChartProps: objectChartProps } = {
      chartData: objectChartData,
      options: defaultBarOptions
    };

    const { barChartProps: styleChartProps } = {
      chartData: styleChartData,
      options: defaultBarOptions
    };

    const { barChartProps: audienceChartProps } = {
      chartData: audienceChartData,
      options: defaultBarOptions
    };

    // Product Word Chart Options
    const productWordChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
          labels: {
            color: '#02e37c',
            font: {
              size: 12
            }
          }
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.label || '';
              const value = context.raw || 0;
              return `${label}: ${(value * 100).toFixed(1)}%`;
            }
          }
        }
      }
    };

    // Product Word Chart Data
    const productWordChartData = computed(() => {
      if (!data.value.product_word_analysis) return null;

      const ratios = data.value.product_word_analysis.word_ratios;
      const labels = Object.keys(ratios);
      const values = Object.values(ratios);

      return {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: [
           'rgba(205, 235, 46, 0.5)',
           'rgba(56, 20, 40, 0.5)',
           'rgba(164, 193, 13, 0.5)',
           'rgba(93, 56, 76, 0.5)',
           'rgba(216, 244, 72, 0.5)'
           ]
        }]
      };
    });

    // Fetch product word analysis data
    const fetchProductWordAnalysis = async (url) => {
      chartStates.value.productWord.loading = true;
      chartStates.value.productWord.error = null;

      try {
        const response = await axios.get(`${apiConfig.baseURL}/app/charts/product-word-analysis/${encodeURIComponent(url)}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        });

        if (response.data.success) {
          data.value.product_word_analysis = response.data.data.analysis;
        } else {
          throw new Error(response.data.error || 'Failed to fetch product word analysis');
        }
      } catch (error) {
        console.error('Error fetching product word analysis:', error);
        chartStates.value.productWord.error = error.message;
      } finally {
        chartStates.value.productWord.loading = false;
      }
    };

    // Methods
    async function fetchDataFromBackend(url) {
      if (!url) return;
      
      console.log('Starting data fetch for:', url);
      
      // Set loading states
      Object.keys(chartStates.value).forEach(key => {
        chartStates.value[key].loading = true;
        chartStates.value[key].error = null;
      });
      
      try {
        // Fetch all analyses in parallel
        const results = await Promise.allSettled([
          fetchToneAnalysis(url),
          fetchEmotionAnalysis(url),
          fetchObjectAnalysis(url),
          fetchImageAnalysis(url),
          fetchInstagramComparison(url),
          fetchProductWordAnalysis(url)
        ]);

        console.log('Fetch results:', results);

        // Check if we got any successful results
        const hasData = results.some(result => 
          result.status === 'fulfilled' && 
          result.value !== undefined && 
          result.value !== null
        );

        if (!hasData) {
          console.error('No data received from any analysis endpoint');
          throw new Error('No analysis data available');
        }

        // Force chart updates
        await nextTick();
        const charts = document.querySelectorAll('canvas');
        charts.forEach(canvas => {
          const chart = ChartJS.getChart(canvas);
          if (chart) {
            console.log('Updating chart:', chart.id);
            chart.update();
          }
        });

        return hasData;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      } finally {
        // Reset loading states
        Object.keys(chartStates.value).forEach(key => {
          chartStates.value[key].loading = false;
        });
      }
    }

    const getHeaders = () => {
      const token = localStorage.getItem('userToken');
      if (!token) {
        throw new Error('No access token found');
      }
      return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };
    };

    async function fetchToneAnalysis(url) {
      chartStates.value.tone.loading = true;
      chartStates.value.tone.error = null;
      try {
        const headers = getHeaders();
        const response = await axios.get(
          `${apiConfig.baseURL}/app/charts/tone-analysis/${encodeURIComponent(url)}`,
          { headers }
        );
        console.log('Raw tone analysis response:', response);
        console.log('Tone analysis data:', response.data);
        
        if (!response.data?.data) {
          throw new Error('Invalid response format');
        }
        
        data.value.tone_analysis = response.data;
      } catch (error) {
        console.error('Error fetching tone analysis:', error);
        chartStates.value.tone.error = error.response?.data?.error || 'Failed to load tone analysis';
      } finally {
        chartStates.value.tone.loading = false;
      }
    }

    async function fetchEmotionAnalysis(url) {
      chartStates.value.emotion.loading = true;
      chartStates.value.emotion.error = null;
      try {
        const headers = getHeaders();
        const response = await axios.get(
          `${apiConfig.baseURL}/app/charts/emotion-analysis/${encodeURIComponent(url)}`,
          { headers }
        );
        data.value.emotion_analysis = response.data;
      } catch (error) {
        console.error('Error fetching emotion analysis:', error);
        chartStates.value.emotion.error = error.response?.data?.error || 'Failed to load emotion analysis';
        if (error.message === 'No access token found') {
          router.push('/user-login');
        }
      } finally {
        chartStates.value.emotion.loading = false;
      }
    }

    async function fetchObjectAnalysis(url) {
      chartStates.value.object.loading = true;
      chartStates.value.object.error = null;
      try {
        const headers = getHeaders();
        // Remove protocol and www from URL before making request
        const cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '');
        const response = await axios.get(
          `${apiConfig.baseURL}/app/charts/object-analysis/${encodeURIComponent(cleanUrl)}`,
          { headers }
        );
        data.value.object_analysis = response.data;
      } catch (error) {
        console.error('Error fetching object analysis:', error);
        chartStates.value.object.error = error.response?.data?.error || 'Failed to load object analysis';
        if (error.message === 'No access token found') {
          router.push('/user-login');
        }
      } finally {
        chartStates.value.object.loading = false;
      }
    }

    async function fetchImageAnalysis(url) {
      try {
        const headers = getHeaders();
        const cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '');
        const response = await axios.get(
          `${apiConfig.baseURL}/app/charts/image-analysis/${encodeURIComponent(cleanUrl)}`,
          { headers }
        );

        console.log('Image analysis response:', response.data);

        if (response.data?.images) {
          console.log('Raw image data from API:', response.data.images);
          images.value = response.data.images.map(img => {
            console.log('Processing image:', img);
            
            // Construct full URL using API base URL
            const imageUrl = img.image_url.startsWith('http') 
              ? img.image_url 
              : `${apiConfig.baseURL}${img.image_url}`;
            
            console.log('Constructed image URL:', imageUrl);
            console.log('Raw object_results:', img.object_results);
            
            const processedImage = {
              url: imageUrl,
              alt: 'Analyzed image',
              created_at: img.created_at,
              object_results: Array.isArray(img.object_results) ? img.object_results : [],
              style_results: typeof img.style_results === 'object' ? img.style_results : {},
              face_results: Array.isArray(img.face_results) ? img.face_results : [],
              instagram_handle: img.instagram_handle,
              brand_url: img.brand_url
            };
            
            console.log('Processed image object_results:', processedImage.object_results);
            console.log('Processed image style_results:', processedImage.style_results);
            return processedImage;
          });
          
          console.log('Final images array:', images.value);
          console.log('First image object_results:', images.value[0]?.object_results);
        } else {
          console.log('No images found in response');
          images.value = [];
        }
      } catch (error) {
        console.error('Error fetching image analysis:', error);
        error.value = 'Failed to load image analysis';
        images.value = [];
      }
    }

    const fetchLastAnalyzedUrl = async () => {
      try {
        const token = localStorage.getItem('userToken');
        if (!token) {
          console.error('No JWT token found');
          return null;
        }

        const response = await axios.get(
          `${apiConfig.baseURL}/app/user/last-url`,
          { headers: { 'Authorization': `Bearer ${token}` } }
        );

        if (response.data && response.data.url) {
          lastAnalyzedUrl.value = response.data.url;
          localStorage.setItem('lastAnalyzedUrl', response.data.url);
          return response.data.url;
        }
        return null;
      } catch (err) {
        console.error('Error fetching last analyzed URL:', err);
        return null;
      }
    };

    const handleLogout = () => {
      localStorage.removeItem('userToken');
      localStorage.removeItem('username');
      localStorage.removeItem('lastAnalyzedUrl');
      window.location.href = '/user-login';
    };

    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('userToken');
        if (!token) {
          router.push('/user-login');  
          return;
        }

        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        };

        const response = await axios.get(`${apiConfig.baseURL}/app/user-details`, { headers });

        if (response.data) {
          console.log('User details response:', response.data);
          console.log('Profile picture data:', response.data.profile_pic);
          user.value = response.data;
          localStorage.setItem('subscriptionStatus', response.data.subscription_status);
        } else {
          throw new Error('No user data received');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        if (error.response?.status === 401) {
          localStorage.removeItem('userToken');
          localStorage.removeItem('subscriptionStatus');
          router.push('/user-login');  
        }
        throw error;
      }
    };

    // Watch for route changes
    watch(
      () => route.query,
      async (newQuery) => {
        if (newQuery.url) {
          try {
            // Decode the URL from the query parameter
            const decodedUrl = decodeURIComponent(newQuery.url);
            currentUrl.value = decodedUrl;
            await fetchDataFromBackend(decodedUrl);
          } catch (error) {
            console.error('Error processing URL:', error);
            error.value = 'Invalid URL format';
          }
        }
      },
      { immediate: true }
    );

    // Watch for data changes
    watch(data, (newData) => {
      if (newData.tone_analysis?.data || newData.emotion_analysis?.data) {
        // Force chart update
        nextTick(() => {
          const charts = document.querySelectorAll('canvas');
          charts.forEach(canvas => {
            const chart = ChartJS.getChart(canvas);
            if (chart) {
              chart.update();
            }
          });
        });
      }
    }, { deep: true });

    const handleUrlSubmit = async () => {
      if (!newUrl.value) return;
      
      // Basic URL validation
      const urlPattern = /^[^/\s]+\.[^/\s]+.*$/;
      if (!urlPattern.test(newUrl.value)) {
        console.error('Invalid URL format');
        return;
      }
      
      isSubmitting.value = true;
      error.value = null; // Reset error state
      
      try {
        // Only remove protocol, preserve www if it exists
        const cleanUrl = newUrl.value.replace(/^(https?:\/\/)/, '');
        const fullUrl = `https://${cleanUrl}`;

        // Get current user token
        const token = localStorage.getItem('userToken');
        if (!token) {
          throw new Error('No access token found');
        }

        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        // Reset existing data
        data.value = {
          tone_analysis: null,
          emotion_analysis: null,
          object_analysis: null,
          style_analysis: null,
          audience_analysis: null
        };

        // Submit URL and wait for analysis to complete
        const response = await axios.post(
          `${apiConfig.baseURL}/app/submit-url`,
          { url: fullUrl },
          { headers }
        );

        if (response.data.redirect_url) {
          // Update the URL in the router first
          await router.replace({ 
            query: { ...route.query, url: encodeURIComponent(fullUrl) }
          });

          // Add a delay to ensure backend processing is complete
          await new Promise(resolve => setTimeout(resolve, 2000));
          
          // Fetch data with retries
          let retryCount = 0;
          const maxRetries = 3;
          let dataFetched = false;
          
          while (retryCount < maxRetries && !dataFetched) {
            try {
              console.log(`Attempting to fetch data, attempt ${retryCount + 1}`);
                await fetchDataFromBackend(fullUrl);
              
              // Verify that we have received some data
              if (data.value.tone_analysis || data.value.emotion_analysis || data.value.object_analysis) {
                dataFetched = true;
                console.log('Data successfully fetched');
              } else {
                console.log('No data received, retrying...');
                await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds between retries
              }
            } catch (error) {
              console.error(`Error fetching data on attempt ${retryCount + 1}:`, error);
            }
            retryCount++;
          }
          
          if (!dataFetched) {
            console.error('Failed to fetch data after all retries');
            error.value = 'Failed to load analysis data. Please refresh the page.';
            }
          
          // Clear input only after successful fetch
          newUrl.value = '';
          
          // Force a final chart update
          await nextTick();
          const charts = document.querySelectorAll('canvas');
          charts.forEach(canvas => {
            const chart = ChartJS.getChart(canvas);
            if (chart) {
              chart.update();
            }
          });
        }
      } catch (error) {
        console.error('Error submitting URL:', error);
        if (error.message === 'No access token found') {
          router.push('/user-login');
        } else {
          error.value = error.response?.data?.message || 'Failed to analyze URL';
        }
      } finally {
        isSubmitting.value = false;
      }
    };

    // Function to parse JWT token and get expiration time
    const getTokenExpiration = (token) => {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const payload = JSON.parse(jsonPayload);
        return payload.exp * 1000; // Convert to milliseconds
      } catch (error) {
        console.error('Error parsing token:', error);
        return null;
      }
    };

    // Function to check token expiration and handle refresh/logout
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('userToken');
      if (!token) {
        handleTokenExpired();
        return;
      }

      const expirationTime = getTokenExpiration(token);
      if (!expirationTime) {
        handleTokenExpired();
        return;
      }

      const now = Date.now();
      const timeLeft = expirationTime - now;
      timeUntilExpiration.value = Math.max(0, Math.floor(timeLeft / 1000)); // Convert to seconds

      // Show warning 5 minutes before expiration
      if (timeLeft <= 300000 && timeLeft > 0) { // 5 minutes in milliseconds
        showTokenWarning.value = true;
      }

      // Token has expired
      if (timeLeft <= 0) {
        handleTokenExpired();
      }
    };

    // Function to handle token expiration
    const handleTokenExpired = () => {
      clearInterval(tokenRefreshInterval.value);
      localStorage.removeItem('userToken');
      localStorage.removeItem('username');
      localStorage.removeItem('lastAnalyzedUrl');
      router.push('/user-login');
    };

    // Function to refresh token
    const refreshToken = async () => {
      try {
        const currentToken = localStorage.getItem('userToken');
        if (!currentToken) {
          throw new Error('No token found');
        }

        const response = await axios.post(
          `${apiConfig.baseURL}/app/refresh-token`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${currentToken}`,
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.data?.token) {
          localStorage.setItem('userToken', response.data.token);
          showTokenWarning.value = false;
          const newExpiration = getTokenExpiration(response.data.token);
          if (newExpiration) {
            tokenExpirationTime.value = newExpiration;
          }
        } else {
          throw new Error('No token in response');
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        handleTokenExpired();
      }
    };

    // Start token monitoring on component mount
    onMounted(async () => {
      try {
        const token = localStorage.getItem('userToken');
        if (!token) {
          router.push('/user-login');
          return;
        }

        const expirationTime = getTokenExpiration(token);
        if (expirationTime) {
          tokenExpirationTime.value = expirationTime;
          // Check token every minute
          tokenRefreshInterval.value = setInterval(checkTokenExpiration, 60000);
          // Initial check
          checkTokenExpiration();
        }

        // Existing onMounted code...
        await fetchUserDetails();
        
        if (route.query.url) {
          currentUrl.value = decodeURIComponent(route.query.url);
          await fetchDataFromBackend(currentUrl.value);
        } else {
          const lastUrl = await fetchLastAnalyzedUrl();
          if (lastUrl) {
            currentUrl.value = lastUrl;
            await fetchDataFromBackend(lastUrl);
          }
        }
      } catch (error) {
        console.error('Error in component mount:', error);
        if (error.response?.status === 401) {
          router.push('/user-login');
        }
      }
    });

    // Cleanup interval on component unmount
    onBeforeUnmount(() => {
      if (tokenRefreshInterval.value) {
        clearInterval(tokenRefreshInterval.value);
      }
    });

    // Add this computed property
    const isDevelopment = computed(() => {
      return __VUE_OPTIONS_API__;  // This is true in development mode
    });

    const formatUrl = (url) => {
      if (!url) return '';
      return url.replace(/^https?:\/\/(www\.)?/, '');
    };

    // Add these watchers to monitor data changes
    watch(() => data.value.tone_analysis, (newVal) => {
      console.log('Tone analysis updated:', newVal);
    }, { deep: true });

    watch(() => data.value.emotion_analysis, (newVal) => {
      console.log('Emotion analysis updated:', newVal);
    }, { deep: true });

    watch(() => data.value.object_analysis, (newVal) => {
      console.log('Object analysis updated:', newVal);
    }, { deep: true });

    // Watch currentUrl changes
    watch(() => currentUrl.value, async (newUrl) => {
      if (newUrl) {
        console.log('URL changed, fetching new data for:', newUrl);
        await fetchDataFromBackend(newUrl);
      }
    });

    // Watch loading state changes
    watch(() => isSubmitting.value, async (newVal, oldVal) => {
      if (oldVal === true && newVal === false) {
        // Loading just finished, ensure data is refreshed
        console.log('Loading finished, refreshing charts...');
        await nextTick();
        const charts = document.querySelectorAll('canvas');
        charts.forEach(canvas => {
          const chart = ChartJS.getChart(canvas);
          if (chart) {
            chart.update();
          }
        });
      }
    });

    // Add new refs for image carousel
    const currentAnalysis = ref('object');
    const currentImageIndex = ref(0);
    const images = ref([]);

    // Add methods for carousel control
    const nextImage = () => {
      if (currentImageIndex.value < images.value.length - 1) {
        currentImageIndex.value++;
      } else {
        currentImageIndex.value = 0;
      }
    };

    const previousImage = () => {
      if (currentImageIndex.value > 0) {
        currentImageIndex.value--;
      } else {
        currentImageIndex.value = images.value.length - 1;
      }
    };

    const setCurrentImage = (index) => {
      currentImageIndex.value = index;
    };

    const currentImage = computed(() => {
      const image = images.value[currentImageIndex.value];
      if (!image) {
        console.log('No current image found');
        return null;
      }

      console.log('Current image before processing:', image);
      
      // Process style results if they exist
      let processedStyleResults = null;
      if (image.style_results) {
        try {
          console.log('Raw style results:', image.style_results);
          
          // Parse style_results if it's a string
          const styleData = typeof image.style_results === 'string' 
            ? JSON.parse(image.style_results) 
            : image.style_results;
          
          console.log('Parsed style data:', styleData);
          
          // Extract style analysis data from the correct nested structure
          const styleAnalysis = styleData.style_analysis || styleData;
          
          console.log('Extracted style analysis:', styleAnalysis);
          
          processedStyleResults = {
            colors: styleAnalysis.colors || [],
            dominant_colors: styleAnalysis.dominant_colors || [],
            aesthetic_score: Number(styleAnalysis.aesthetic_score || 0),
            style_attributes: {
              image_type: styleAnalysis.style_attributes?.image_type || 'Unknown',
              lighting: styleAnalysis.style_attributes?.lighting || 'Unknown',
              color_tone: styleAnalysis.style_attributes?.color_tone || 'Unknown',
              mood: styleAnalysis.style_attributes?.mood || 'Unknown',
              composition: styleAnalysis.style_attributes?.composition || 'Unknown'
            },
            image_quality: {
              sharpness: styleAnalysis.image_quality?.sharpness || 'Unknown',
              contrast: styleAnalysis.image_quality?.contrast || 'Unknown',
              noise_level: styleAnalysis.image_quality?.noise_level || 'Unknown',
              exposure: styleAnalysis.image_quality?.exposure || 'Unknown'
            }
          };
          
          console.log('Final processed style results:', processedStyleResults);
        } catch (error) {
          console.error('Error processing style results:', error);
          console.error('Raw style results:', image.style_results);
          processedStyleResults = null;
        }
      }

      const processedImage = {
        ...image,
        object_results: Array.isArray(image.object_results) ? image.object_results : [],
        style_results: processedStyleResults,
        face_results: Array.isArray(image.face_results) ? image.face_results : []
      };

      console.log('Processed current image:', processedImage);
      console.log('Processed style results:', processedImage.style_results);
      
      return processedImage;
    });

    const getMarkerStyle = (object) => {
      console.log('getMarkerStyle called with object:', object);
      
      if (!object || !object.bbox) {
        console.log('Invalid object or missing bbox:', object);
        return {};
      }
      
      // Convert normalized coordinates to percentages
      const bbox = {
        x: object.bbox.x * 100,
        y: object.bbox.y * 100,
        width: object.bbox.width * 100,
        height: object.bbox.height * 100
      };
      
      console.log('Calculated bbox:', bbox);

      return {
        position: 'absolute',
        left: `${bbox.x}%`,
        top: `${bbox.y}%`,
        width: `${bbox.width}%`,
        height: `${bbox.height}%`,
        border: '2px solid rgba(255, 255, 0, 0.8)',
        backgroundColor: 'rgba(255, 255, 0, 0.2)',
        color: 'white',
        padding: '4px',
        fontSize: '12px',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textShadow: '1px 1px 2px black',
        zIndex: 10
      };
    };

    const getBrandMarkerStyle = (brand) => {
      if (!brand || !brand.bbox) return {};
      
      const bbox = brand.bbox;
      return {
        position: 'absolute',
        left: `${bbox.x * 100}%`,
        top: `${bbox.y * 100}%`,
        width: `${bbox.width * 100}%`,
        height: `${bbox.height * 100}%`,
        border: '2px solid rgba(0, 255, 0, 0.8)',
        backgroundColor: 'rgba(0, 255, 0, 0.2)',
        color: 'white',
        padding: '4px',
        fontSize: '12px',
        pointerEvents: 'none'
      };
    };

    const getFaceMarkerStyle = (face) => {
      if (!face || !face.bbox) return {};
      
      const bbox = face.bbox;
      return {
        position: 'absolute',
        left: `${bbox.x * 100}%`,
        top: `${bbox.y * 100}%`,
        width: `${bbox.width * 100}%`,
        height: `${bbox.height * 100}%`,
        border: '2px solid rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        color: 'white',
        padding: '4px',
        fontSize: '12px',
        pointerEvents: 'none'
      };
    };

    // Add the setAnalysisType method in the setup function
    const setAnalysisType = (type) => {
      console.log('Setting analysis type to:', type);
      console.log('Current image before change:', currentImage.value);
      currentAnalysis.value = type;
      nextTick(() => {
        console.log('Current image after change:', currentImage.value);
        console.log('Current analysis type:', currentAnalysis.value);
      });
    };

    // Add fetch function for Instagram comparison data
    async function fetchInstagramComparison(url) {
      const requestId = Date.now(); // Generate unique ID for this request
      console.log(`[Instagram Comparison ${requestId}] Starting request`);
      
      // Prevent duplicate requests
      if (isLoadingInstagramComparison.value) {
        console.log(`[Instagram Comparison ${requestId}] Request already in progress, skipping`);
        console.log('Current loading state:', isLoadingInstagramComparison.value);
        return;
      }

      console.log(`[Instagram Comparison ${requestId}] Setting loading state to true`);
      chartStates.value.instagramComparison.loading = true;
      chartStates.value.instagramComparison.error = null;
      isLoadingInstagramComparison.value = true;

      try {
        const headers = getHeaders();
        // Clean the URL by removing protocol and www
        const cleanUrl = url.replace(/^(https?:\/\/)?/, '');
        console.log(`[Instagram Comparison ${requestId}] Request details:`, {
          originalUrl: url,
          cleanedUrl: cleanUrl,
          encodedUrl: encodeURIComponent(cleanUrl)
        });

        const endpoint = `${apiConfig.baseURL}/app/charts/instagram-comparison/${encodeURIComponent(cleanUrl)}`;
        console.log(`[Instagram Comparison ${requestId}] Endpoint:`, endpoint);
        console.log(`[Instagram Comparison ${requestId}] Headers:`, headers);
        
        console.log(`[Instagram Comparison ${requestId}] Making request...`);
        const response = await axios.get(endpoint, { headers });
        console.log(`[Instagram Comparison ${requestId}] Response received:`, {
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
        });
        console.log(`[Instagram Comparison ${requestId}] Response data:`, JSON.stringify(response.data, null, 2));

        // Log the structure of each dataset
        if (response.data) {
          console.log(`[Instagram Comparison ${requestId}] Dataset structure:`);
          Object.entries(response.data).forEach(([key, dataset]) => {
            console.log(`[Instagram Comparison ${requestId}] ${key} dataset:`, {
              hasLabels: Array.isArray(dataset?.labels),
              labelsLength: dataset?.labels?.length,
              hasDatasets: Array.isArray(dataset?.datasets),
              datasetsLength: dataset?.datasets?.length,
              firstDatasetLength: dataset?.datasets?.[0]?.data?.length,
              rawData: dataset
            });
          });
        }

        // Check if we have any non-empty datasets
        const hasData = Object.values(response.data).some(dataset => {
          const hasValidData = dataset?.labels?.length > 0 && 
                             dataset?.datasets?.some(ds => ds?.data?.length > 0);
          console.log(`[Instagram Comparison ${requestId}] Dataset validation:`, {
            hasLabels: dataset?.labels?.length > 0,
            hasDatasets: dataset?.datasets?.some(ds => ds?.data?.length > 0),
            isValid: hasValidData
          });
          return hasValidData;
        });

        console.log(`[Instagram Comparison ${requestId}] Has valid data:`, hasData);

        if (hasData) {
          console.log(`[Instagram Comparison ${requestId}] Setting instagramComparisonData`);
          console.log('Raw response data:', response.data);
          
          // Log each dataset's contents
          ['engagement', 'followers', 'likes', 'quality'].forEach(key => {
            const dataset = response.data[key];
            console.log(`[Instagram Comparison ${requestId}] ${key} dataset details:`, {
              labels: dataset?.labels,
              firstDatasetLabel: dataset?.datasets?.[0]?.label,
              firstDatasetLength: dataset?.datasets?.[0]?.data?.length,
              firstDatasetSample: dataset?.datasets?.[0]?.data?.slice(0, 3),
              secondDatasetLabel: dataset?.datasets?.[1]?.label,
              secondDatasetLength: dataset?.datasets?.[1]?.data?.length,
              secondDatasetSample: dataset?.datasets?.[1]?.data?.slice(0, 3)
            });
          });
          
          instagramComparisonData.value = response.data;
          
          // Add detailed debug logging
          console.log('[Instagram Comparison Debug] Data structure check:', {
            engagement: {
              labels: instagramComparisonData.value.engagement?.labels,
              datasets: instagramComparisonData.value.engagement?.datasets?.map(ds => ({
                label: ds.label,
                dataLength: ds.data?.length,
                firstFewPoints: ds.data?.slice(0, 3)
              }))
            },
            followers: {
              labels: instagramComparisonData.value.followers?.labels,
              datasets: instagramComparisonData.value.followers?.datasets?.map(ds => ({
                label: ds.label,
                dataLength: ds.data?.length,
                firstFewPoints: ds.data?.slice(0, 3)
              }))
            },
            likes: {
              labels: instagramComparisonData.value.likes?.labels,
              datasets: instagramComparisonData.value.likes?.datasets?.map(ds => ({
                label: ds.label,
                dataLength: ds.data?.length,
                firstFewPoints: ds.data?.slice(0, 3)
              }))
            },
            quality: {
              labels: instagramComparisonData.value.quality?.labels,
              datasets: instagramComparisonData.value.quality?.datasets?.map(ds => ({
                label: ds.label,
                dataLength: ds.data?.length,
                firstFewPoints: ds.data?.slice(0, 3)
              }))
            }
          });
          
          // Log the chart options being used
          console.log('[Instagram Comparison Debug] Chart options:', lineChartOptions);
        } else {
          console.log(`[Instagram Comparison ${requestId}] No Instagram comparison data available`);
          chartStates.value.instagramComparison.error = 'No Instagram comparison data available';
        }

        // Add a small delay before completing to help identify multiple calls
        await new Promise(resolve => setTimeout(resolve, 100));

      } catch (error) {
        console.error(`[Instagram Comparison ${requestId}] Error:`, error);
        console.error(`[Instagram Comparison ${requestId}] Error response:`, error.response?.data);
        console.error(`[Instagram Comparison ${requestId}] Error status:`, error.response?.status);
        console.error(`[Instagram Comparison ${requestId}] Error headers:`, error.response?.headers);
        chartStates.value.instagramComparison.error = error.response?.data?.error || 'Failed to load Instagram comparison';
      } finally {
        console.log(`[Instagram Comparison ${requestId}] Request complete, resetting loading state`);
        chartStates.value.instagramComparison.loading = false;
        isLoadingInstagramComparison.value = false;
      }
    }

    // Add line chart options
    const lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            usePointStyle: true,
            padding: 20
          }
        },
        tooltip: {
          mode: 'index',
          intersect: false
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            drawBorder: false
          }
        },
        x: {
          grid: {
            display: false
          }
        }
      },
      elements: {
        line: {
          tension: 0.4
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 6
        }
      }
    };

    // Add computed properties for Instagram comparison charts
    const processedInstagramData = computed(() => {
      const rawData = instagramComparisonData.value;
      if (!rawData) return {};

      const processDataset = (dataset) => {
        if (!dataset?.datasets?.length || !dataset?.labels?.length) return null;
        
        return {
          labels: dataset.labels,
          datasets: dataset.datasets.map(ds => ({
            label: ds.label,
            data: ds.data,
            borderColor: ds.borderColor,
            tension: ds.tension || 0.1,
            fill: false
          }))
        };
      };

      return {
        engagement: processDataset(rawData.engagement),
        followers: processDataset(rawData.followers),
        likes: processDataset(rawData.likes),
        quality: processDataset(rawData.quality)
      };
    });

    const isMobile = ref(window.innerWidth <= 768);
    const currentMobileSection = ref(0);

    const setCurrentMobileSection = (index) => {
      currentMobileSection.value = index;
      currentSection.value = index;
    };

    // Handle window resize
    const handleResize = () => {
      isMobile.value = window.innerWidth <= 768;
      if (!isMobile.value) {
        // Reset column visibility on desktop
        currentMobileSection.value = 0;
      }
    };

    // Add to onMounted
    onMounted(() => {
      window.addEventListener('resize', handleResize);
      handleResize(); // Initial check
    });

    // Add to onBeforeUnmount
    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    // Add to setup(), after other refs
    const currentSection = ref(0);

    const setCurrentSection = (index) => {
      currentSection.value = index;
      if (isMobile.value) {
        currentMobileSection.value = index;
      }
    };

    const nextSection = () => {
      currentSection.value = (currentSection.value + 1) % sections.length;
      // Add a fade transition effect
      const chartSections = document.querySelectorAll('.chart-section');
      chartSections.forEach(section => {
        section.style.transition = 'opacity 0.3s ease';
      });
    };

    // Add section names as constants
    const sections = ['Tone', 'Emotion', 'Product', 'Visual', 'Comparison'];
    const sectionsLong = ['Tone Analysis', 'Emotion Analysis', 'Product Analysis', 'Visual Analysis', 'Comparison Data'];

    // Add to setup() after other refs
    const lastScrollPosition = ref(0);
    const isHeaderVisible = ref(true);

    // Add to onMounted
    const handleScroll = () => {
      if (window.innerWidth <= 768) {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        isHeaderVisible.value = currentScrollPosition < lastScrollPosition.value || currentScrollPosition < 50;
        lastScrollPosition.value = currentScrollPosition;
      }
    };

    onMounted(() => {
      // ... existing onMounted code ...
      window.addEventListener('scroll', handleScroll);
    });

    onBeforeUnmount(() => {
      // ... existing onBeforeUnmount code ...
      window.removeEventListener('scroll', handleScroll);
    });

    // Quality Score Chart Data
    const qualityScoreData = computed(() => {
      if (!data.value.style_analysis?.data || !data.value.tone_analysis?.data || !data.value.emotion_analysis?.data) {
        return null;
      }

      // Get style quality metrics
      const styleQuality = data.value.style_analysis.data.image_quality || {};
      const aestheticScore = data.value.style_analysis.data.aesthetic_score || 0;

      // Calculate coherence scores
      const toneEmotionCoherence = calculateToneEmotionCoherence(
        data.value.tone_analysis.data,
        data.value.emotion_analysis.data
      );

      const styleEmotionCoherence = calculateStyleEmotionCoherence(
        data.value.style_analysis.data,
        data.value.emotion_analysis.data
      );

      const objectWordCoherence = calculateObjectWordCoherence(
        data.value.object_analysis,
        data.value.product_word_analysis
      );

      return {
        labels: [
          'Image Quality',
          'Aesthetic Score',
          'Tone-Emotion Coherence',
          'Style-Emotion Coherence',
          'Object-Word Coherence',
          'Overall Impact'
        ],
        datasets: [{
          label: 'Quality Metrics',
          data: [
            (styleQuality.sharpness + styleQuality.contrast + styleQuality.exposure) / 3 || 0,
            aestheticScore,
            toneEmotionCoherence,
            styleEmotionCoherence,
            objectWordCoherence,
            calculateOverallScore()
          ],
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(255, 206, 86, 0.5)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(153, 102, 255, 0.5)',
            'rgba(205, 235, 46, 0.5)'
          ],
          borderWidth: 1
        }]
      };
    });

    // Quality Score Options
    const qualityScoreOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        r: {
          beginAtZero: true,
          max: 1,
          ticks: {
            stepSize: 0.2,
            callback: function(value) {
              return (value * 10).toFixed(1);
            }
          }
        }
      },
      plugins: {
        legend: {
          position: 'right',
          labels: {
            generateLabels: (chart) => {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map((label, i) => ({
                  text: `${label}: ${(data.datasets[0].data[i] * 10).toFixed(1)}/10`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  hidden: false,
                  index: i
                }));
              }
              return [];
            }
          }
        }
      }
    };

    // Helper functions for coherence calculations
    function calculateToneEmotionCoherence(toneData, emotionData) {
      console.log('=== Calculating Tone-Emotion Coherence ===');
      console.log('Input tone data:', toneData);
      console.log('Input emotion data:', emotionData);

      // Normalize emotion scores to lowercase keys
      const normalizedEmotionScores = {};
      Object.entries(emotionData.emotion_scores || {}).forEach(([emotion, score]) => {
        normalizedEmotionScores[emotion.toLowerCase()] = score;
      });
      console.log('Normalized emotion scores:', normalizedEmotionScores);

      const toneEmotionMapping = {
        formal: { professional: 1.0, analytical: 0.8, cautious: 0.6 },
        informal: { friendly: 1.0, relaxed: 0.8, enthusiastic: 0.6 },
        direct: { professional: 0.8, analytical: 0.7, inspiring: 0.6 },
        indirect: { empathetic: 0.8, cautious: 0.7, relaxed: 0.6 },
        technical: { professional: 1.0, analytical: 0.9, cautious: 0.7 },
        simple: { friendly: 0.9, relaxed: 0.8, optimistic: 0.7 },
        persuasive: { inspiring: 1.0, enthusiastic: 0.9, optimistic: 0.8 },
        informative: { professional: 0.8, analytical: 0.7, empathetic: 0.6 }
      };

      let coherenceScore = 0;
      let maxPossibleScore = 0;

      // For each tone in our data
      Object.entries(toneData).forEach(([tone, toneScore]) => {
        console.log(`Processing tone: ${tone} with score: ${toneScore}`);
        if (toneEmotionMapping[tone] && toneScore > 0) {
          // For each mapped emotion for this tone
          Object.entries(toneEmotionMapping[tone]).forEach(([emotion, weight]) => {
            const emotionScore = normalizedEmotionScores[emotion] || 0;
            const contribution = toneScore * emotionScore * weight;
            coherenceScore += contribution;
            maxPossibleScore += weight * toneScore; // Scale max score by tone score
            console.log(`  - Emotion: ${emotion}, Score: ${emotionScore}, Weight: ${weight}, Contribution: ${contribution}`);
          });
        } else {
          console.log(`  - No mapping found for tone: ${tone} or score is 0`);
        }
      });

      const finalScore = maxPossibleScore > 0 ? (coherenceScore / maxPossibleScore) * 0.4 : 0;
      console.log('Tone-Emotion Coherence Final Score:', finalScore);
      return finalScore;
    }

    function calculateStyleToneCoherence(styleData, toneData) {
      console.log('=== Calculating Style-Tone Coherence ===');
      console.log('Input style data:', styleData);
      console.log('Input tone data:', toneData);

      // If no style data, use a default neutral score
      if (!styleData || Object.keys(styleData).length === 0) {
        console.log('No style data available, using default score of 0.5');
        return 0.5 * 0.2; // 20% weight with neutral score
      }

      const styleAttributes = styleData.style_attributes || {};
      console.log('Style attributes:', styleAttributes);

      const styleToneMapping = {
        formal: ['professional', 'clean', 'minimal', 'structured'],
        informal: ['casual', 'relaxed', 'natural', 'organic'],
        direct: ['bold', 'strong', 'clear', 'focused'],
        indirect: ['subtle', 'soft', 'gentle', 'nuanced'],
        technical: ['precise', 'detailed', 'complex', 'structured'],
        simple: ['minimal', 'clean', 'basic', 'straightforward'],
        persuasive: ['dynamic', 'bold', 'engaging', 'dramatic'],
        informative: ['balanced', 'clear', 'organized', 'structured']
      };

      let coherenceScore = 0;
      let totalWeight = 0;

      Object.entries(toneData).forEach(([tone, toneScore]) => {
        if (toneScore > 0 && styleToneMapping[tone]) {
          console.log(`Processing tone: ${tone} with score: ${toneScore}`);
          const matchingStyles = styleToneMapping[tone].filter(style => 
            Object.values(styleAttributes).some(attr => {
              const matches = attr.toLowerCase().includes(style.toLowerCase());
              console.log(`  - Checking style: ${style} against attribute: ${attr}, matches: ${matches}`);
              return matches;
            })
          );
          
          if (matchingStyles.length > 0) {
            const contribution = toneScore * (matchingStyles.length / styleToneMapping[tone].length);
            coherenceScore += contribution;
            totalWeight += toneScore;
            console.log(`  - Found ${matchingStyles.length} matching styles, contribution: ${contribution}`);
          }
        }
      });

      const finalScore = totalWeight > 0 ? (coherenceScore / totalWeight) * 0.2 : 0.1;
      console.log('Style-Tone Coherence Final Score:', finalScore);
      return finalScore;
    }

    function calculateStyleEmotionCoherence(styleData, emotionData) {
      console.log('=== Calculating Style-Emotion Coherence ===');
      console.log('Input style data:', styleData);
      console.log('Input emotion data:', emotionData);

      // If no style data, use a default neutral score
      if (!styleData || Object.keys(styleData).length === 0) {
        console.log('No style data available, using default score of 0.5');
        return 0.5 * 0.2; // 20% weight with neutral score
      }

      // Normalize emotion scores to lowercase keys
      const normalizedEmotions = {};
      Object.entries(emotionData.emotion_scores || {}).forEach(([emotion, score]) => {
        normalizedEmotions[emotion.toLowerCase()] = score;
      });
      console.log('Normalized emotions:', normalizedEmotions);

      const styleEmotionMapping = {
        friendly: ['warm', 'natural', 'casual', 'inviting'],
        relaxed: ['soft', 'gentle', 'balanced', 'harmonious'],
        inspiring: ['dramatic', 'artistic', 'creative', 'dynamic'],
        enthusiastic: ['vibrant', 'energetic', 'bold', 'dynamic'],
        optimistic: ['bright', 'warm', 'uplifting', 'positive'],
        professional: ['clean', 'minimal', 'structured', 'formal'],
        analytical: ['precise', 'detailed', 'structured', 'organized'],
        cautious: ['balanced', 'subtle', 'controlled', 'measured'],
        empathetic: ['soft', 'warm', 'gentle', 'natural']
      };

      const styleAttributes = styleData.style_attributes || {};
      
      console.log('Style attributes:', styleAttributes);
      console.log('Normalized emotion scores:', normalizedEmotions);

      let coherenceScore = 0;
      let totalWeight = 0;

      Object.entries(normalizedEmotions).forEach(([emotion, emotionScore]) => {
        if (emotionScore > 0 && styleEmotionMapping[emotion]) {
          console.log(`Processing emotion: ${emotion} with score: ${emotionScore}`);
          const matchingStyles = styleEmotionMapping[emotion].filter(style => 
            Object.values(styleAttributes).some(attr => {
              const matches = attr.toLowerCase().includes(style.toLowerCase());
              console.log(`  - Checking style: ${style} against attribute: ${attr}, matches: ${matches}`);
              return matches;
            })
          );
          
          if (matchingStyles.length > 0) {
            const contribution = emotionScore * (matchingStyles.length / styleEmotionMapping[emotion].length);
            coherenceScore += contribution;
            totalWeight += emotionScore;
            console.log(`  - Found ${matchingStyles.length} matching styles, contribution: ${contribution}`);
          }
        }
      });

      const finalScore = totalWeight > 0 ? (coherenceScore / totalWeight) * 0.2 : 0.1;
      console.log('Style-Emotion Coherence Final Score:', finalScore);
      return finalScore;
    }

    function calculateObjectWordCoherence(objectAnalysis, productWordAnalysis) {
      console.log('=== Calculating Object-Word Coherence ===');
      console.log('Input object analysis:', objectAnalysis);
      console.log('Input product word analysis:', productWordAnalysis);

      // If either analysis is missing, use a default neutral score
      if (!objectAnalysis?.objects || !productWordAnalysis?.word_ratios) {
        console.log('Missing required data for object-word coherence calculation, using default score of 0.5');
        return 0.5 * 0.2; // 20% weight with neutral score
      }

      const objectCounts = {};
      objectAnalysis.objects.forEach(obj => {
        obj.objects.forEach(item => {
          const name = item.name || item.label;
          objectCounts[name.toLowerCase()] = (objectCounts[name.toLowerCase()] || 0) + 1;
        });
      });

      console.log('Object counts:', objectCounts);
      console.log('Word ratios:', productWordAnalysis.word_ratios);

      const wordRatios = productWordAnalysis.word_ratios;
      let coherenceScore = 0;
      let totalRatio = 0;

      Object.entries(wordRatios).forEach(([word, ratio]) => {
        const wordLower = word.toLowerCase();
        console.log(`Checking word: ${wordLower} with ratio: ${ratio}`);
        if (objectCounts[wordLower]) {
          coherenceScore += ratio;
          console.log(`  - Match found! Contribution: ${ratio}`);
        }
        totalRatio += ratio;
      });

      const finalScore = totalRatio > 0 ? (coherenceScore / totalRatio) * 0.2 : 0.1;
      console.log('Object-Word Coherence Final Score:', finalScore);
      return finalScore;
    }

    function calculateOverallScore() {
      console.log('\n=== Starting CP3 Score Calculation ===');
      
      if (!data.value.tone_analysis?.data || !data.value.emotion_analysis?.data) {
        console.log('Missing required tone or emotion data for CP3 score calculation');
        return 0;
      }

      // Calculate each component
      const toneEmotionScore = calculateToneEmotionCoherence(
        data.value.tone_analysis.data,
        data.value.emotion_analysis.data
      );

      const styleToneScore = calculateStyleToneCoherence(
        data.value.style_analysis?.data || {},
        data.value.tone_analysis.data
      );

      const styleEmotionScore = calculateStyleEmotionCoherence(
        data.value.style_analysis?.data || {},
        data.value.emotion_analysis.data
      );

      const objectWordScore = calculateObjectWordCoherence(
        data.value.object_analysis,
        data.value.product_word_analysis
      );

      console.log('\n=== CP3 Score Component Summary ===');
      console.log('Tone-Emotion Score (40%):', toneEmotionScore);
      console.log('Style-Tone Score (20%):', styleToneScore);
      console.log('Style-Emotion Score (20%):', styleEmotionScore);
      console.log('Object-Word Score (20%):', objectWordScore);

      // Sum all components and multiply by 10 to get score out of 10
      const totalScore = (toneEmotionScore + styleToneScore + styleEmotionScore + objectWordScore) * 10;
      console.log('Final CP3 Score:', totalScore.toFixed(1));
      
      return totalScore.toFixed(1);
    }

    const handleProfilePicError = (event) => {
      console.error('Profile picture failed to load:', event);
      event.target.src = '/default-profile.png';
    };

    const getProfilePicture = computed(() => {
      console.log('User data:', user.value);
      if (!user.value) return '/default-profile.png';
      
      // Get profile data
      let profileData = null;
      if (user.value.profile_data) {
        try {
          profileData = typeof user.value.profile_data === 'string' 
            ? JSON.parse(user.value.profile_data) 
            : user.value.profile_data;
        } catch (error) {
          console.error('Error parsing profile data:', error);
        }
      }

      // If we have fbid, use it to construct the profile picture path
      if (profileData?.fbid) {
        const localPath = `${apiConfig.baseURL}/images/profile_pic_${profileData.fbid}.jpg`;
        console.log('Using fbid-based profile picture path:', localPath);
        return localPath;
      }
      
      // Fallback to direct profile_pic or default
      if (user.value.profile_pic) {
        console.log('Using direct profile picture:', user.value.profile_pic);
        return user.value.profile_pic;
      }
      
      console.log('Using default profile picture');
      return '/default-profile.png';
    });

    // Add new watch for isSubmitting to update loading message
    watch(() => isSubmitting.value, (newVal) => {
      if (newVal) {
        loadingMessage.value = 'Starting analysis...';
        // Set up polling for status updates
        const statusInterval = setInterval(async () => {
          if (!isSubmitting.value) {
            clearInterval(statusInterval);
            return;
          }
          try {
            const token = localStorage.getItem('userToken');
            if (!token) {
              clearInterval(statusInterval);
              isSubmitting.value = false;
              return;
            }

            const response = await axios.get(`${apiConfig.baseURL}/app/analysis-status`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            
            if (response.data) {
              loadingMessage.value = response.data.message || 'Processing...';
              taskProgress.value = response.data.progress || 0;
              
              // Check if analysis is complete and force_refresh is true
              if (response.data.status === 'completed' && response.data.force_refresh) {
                clearInterval(statusInterval);
                isSubmitting.value = false;
                // Force a page reload
                window.location.reload();
                return;
              }
            }
          } catch (error) {
            console.error('Error fetching status:', error);
            if (error.response?.status === 422 || error.response?.status === 401) {
              // Token might be expired, try to refresh
              try {
                await refreshToken();
              } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                clearInterval(statusInterval);
                isSubmitting.value = false;
                router.push('/user-login');
              }
            }
          }
        }, 1000); // Poll every second
      } else {
        loadingMessage.value = 'Analysis complete!';
        taskProgress.value = 100;
      }
    });

    // Remove the first watcher and keep only one optimized watcher
    watch(() => isSubmitting.value, (newVal) => {
      if (newVal) {
        // Start polling when submission begins
        loadingMessage.value = 'Starting analysis...';
        taskProgress.value = 0;
        
        // Clear any existing interval
        if (pollingInterval.value) {
          clearInterval(pollingInterval.value);
          pollingInterval.value = null;
        }
        
        // Set up new polling interval
        pollingInterval.value = setInterval(async () => {
          try {
            const response = await axios.get(`${apiConfig.baseURL}/app/analysis-status`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
            });
            
            console.log('Status update received:', response.data); // Debug log
            
            if (response.data) {
              loadingMessage.value = response.data.message || 'Processing...';
              taskProgress.value = response.data.progress || 0;
              
              // If analysis is complete or errored, stop polling
              if (response.data.status === 'completed' || response.data.status === 'error') {
                if (pollingInterval.value) {
                  clearInterval(pollingInterval.value);
                  pollingInterval.value = null;
                }
                isSubmitting.value = false;
              }
            }
          } catch (error) {
            console.error('Error polling status:', error);
            // On error, stop polling and show error state
            if (pollingInterval.value) {
              clearInterval(pollingInterval.value);
              pollingInterval.value = null;
            }
            loadingMessage.value = 'Error occurred during analysis';
            isSubmitting.value = false;
          }
        }, 500); // Poll every 500ms for responsive updates
      } else {
        // Stop polling when submission ends
        if (pollingInterval.value) {
          clearInterval(pollingInterval.value);
          pollingInterval.value = null;
        }
      }
    });

    // Clean up polling on component unmount
    onBeforeUnmount(() => {
      if (pollingInterval.value) {
        clearInterval(pollingInterval.value);
        pollingInterval.value = null;
      }
    });

    return {
      data,
      error,
      loading,
      user,
      handleLogout,
      chartStates,
      handleChartError,
      toneChartData,
      emotionChartData,
      objectChartData,
      toneChartOptions,
      emotionChartOptions,
      defaultBarOptions,
      newUrl,
      isSubmitting,
      handleUrlSubmit,
      formatUrl,
      showTokenWarning,
      timeUntilExpiration,
      refreshToken,
      loadingMessage,
      taskProgress,
      currentAnalysis,
      currentImageIndex,
      images,
      currentImage,
      nextImage,
      previousImage,
      setCurrentImage,
      getMarkerStyle,
      getBrandMarkerStyle,
      getFaceMarkerStyle,
      setAnalysisType,
      instagramComparisonData: processedInstagramData,
      lineChartOptions,
      isMobile,
      currentMobileSection,
      setCurrentMobileSection,
      currentSection,
      setCurrentSection,
      nextSection,
      sections,
      sectionsLong,
      isHeaderVisible,
      productWordChartData,
      productWordChartOptions,
      qualityScoreData,
      qualityScoreOptions,
      calculateOverallScore,
      getProfilePicture,
    };
  }
};
</script>
