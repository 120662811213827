import EarlyAccess from "@/views/EarlyAccess.vue";

const apiConfig = {
    baseURL: process.env.VUE_APP_API_URL || 'https://app.cp3.ai',
    endpoints: {
        login: '/app/login',
        demoLogin: '/app/demo-login',
        signup: '/app/register',
        userDetails: '/app/user-details',
        submitUrl: '/app/submit-url',
        forgotPassword: '/app/forgot-password',
        resetPassword: '/app/reset-password',
        verifyToken: '/app/verify-reset-token',
        EarlyAccess: '/app/early-access',
        Logout: '/app/logout',
        FetchData: '/app/fetch-data',
        WS: '/app/ws',
    }
};

export default apiConfig;
