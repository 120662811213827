<template>
  <div class="admin-workflows">
    <!-- Loading overlay - only show during processing -->
    <div v-if="isProcessing" class="loading-overlay">
      <div class="loading-content">
        <LoadingWheel 
          :status="loadingMessage" 
          :progress="taskProgress" 
        />
      </div>
    </div>

    <!-- Initial loading state -->
    <div v-if="loading" class="initial-loading">
      <LoadingWheel status="Loading..." />
    </div>

    <!-- Main content - only show when not in initial loading -->
    <template v-else>
      <!-- Sidebar -->
      <SideBar />

      <div class="content-wrapper">
        <!-- Header Section -->
        <header class="header" :class="{ 'hidden': !isHeaderVisible }">
          <div class="header-top">
            <h1>Trend Workflow
              <span v-if="user?.username" class="analyzing-url">
                {{ user.username }}
              </span>
            </h1>
          </div>
          <div class="user-info">
            <img 
              :src="getProfilePicture"
              :alt="user?.instagram_handle + ' profile picture'" 
              @error="handleProfilePicError"
              crossorigin="anonymous"
            />
            <button @click="handleLogout" class="logout-btn">Logout</button>
          </div>
        </header>

         <!-- Main Content -->
         <div class="workflow-container">
          <div class="workflow-controls">
            <select v-model="selectedLink" class="link-dropdown">
              <option value="">Select a link</option>
              <option v-for="link in links" :key="link" :value="link">
                {{ link }}
              </option>
            </select>

            <button 
              @click="startTrendWorkflow"
              :disabled="!selectedLink || isProcessing"
              class="submit-btn"
            >
              {{ isProcessing ? 'Processing...' : 'Start Trend Workflow' }}
            </button>
          </div>

          <!-- Four Column Layout -->
          <div class="modules-grid">
            <div class="module-reference">
              <h3>Reference Trends</h3>
              <div class="module-content">
                <div v-if="results" class="results-window">
                  <pre>{{ referenceTrends }}</pre>
                </div>
                <div v-else class="placeholder">
                  No reference trends data available
                </div>
              </div>
            </div>

            <div class="module-style">
              <h3>Style Trends</h3>
              <div class="module-content">
                <div v-if="results" class="results-window">
                  <pre>{{ styleTrends }}</pre>
                </div>
                <div v-else class="placeholder">
                  No style trends data available
                </div>
              </div>
            </div>

            <div class="module-color">
              <h3>Color Analysis</h3>
              <div class="module-content">
                <div v-if="results" class="results-window">
                  <pre>{{ colorAnalysis }}</pre>
                </div>
                <div v-else class="placeholder">
                  No color analysis available
                </div>
              </div>
            </div>

            <div class="module-object">
              <h3>Object Detection</h3>
              <div class="module-content">
                <div v-if="results" class="results-window">
                  <pre>{{ objectDetection }}</pre>
                </div>
                <div v-else class="placeholder">
                  No object detection data available
                </div>
              </div>
            </div>
          

          <div class="module-mood">
            <h3>Mood Analysis</h3>
            <div class="module-content">
              <div v-if="results" class="results-window">
                <pre>{{ moodAnalysis }}</pre>
              </div>
              <div v-else class="placeholder">
                No mood analysis available
              </div>
            </div>
          </div>
        </div>

          <!-- Status Label -->
          <div class="status-label" :class="{ success: saveStatus === 'success', error: saveStatus === 'error' }">
            {{ statusMessage }}
          </div>

          <!-- Trends Table -->
          <div class="trends-table-container">
            <h3>Latest Trends</h3>
            <table class="trends-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Source</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="trend in latestTrends" :key="trend.id">
                  <td>{{ formatDate(trend.date) }}</td>
                  <td>{{ trend.source }}</td>
                  <td>{{ trend.type }}</td>
                  <td>
                    <span :class="['status-badge', trend.status]">
                      {{ trend.status }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import SideBar from '../components/SideBar.vue';
import LoadingWheel from '@/components/LoadingWheel.vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AdminWorkflows',
  components: {
    SideBar,
    LoadingWheel
  },
  setup() {
    const router = useRouter();
    const links = ref([
      'https://www.humansofnewyork.com/',
      'https://www.pots.group/',
      'https://www.pinterest.com/ideas/mens-fashion/924581335376/',
      'https://styledumonde.com/',
      'http://streetpeeper.com/news/?page=1',
      'https://www.thesartorialist.com/'
    ]);
    const selectedLink = ref('');
    const isProcessing = ref(false);
    const results = ref(null);
    const user = ref(null);
    const loading = ref(true);
    const loadingMessage = ref('');
    const taskProgress = ref(0);
    const saveStatus = ref('');
    const statusMessage = ref('');
    const referenceTrends = ref([]);
    const styleTrends = ref([]);
    const colorAnalysis = ref([]);
    const objectDetection = ref([]);
    const moodAnalysis = ref([]);
    const isHeaderVisible = ref(true);

    const getProfilePicture = computed(() => {
      return user.value?.profile_pic || '../assets/images/default-profile.png';
    });

    const handleProfilePicError = (event) => {
      event.target.src = '../assets/images/default-profile.png';
    };

    const handleLogout = async () => {
      try {
        await axios.post('/api/logout');
        window.location.href = '/login';
      } catch (error) {
        console.error('Logout failed:', error);
      }
    };

    const startTrendWorkflow = async () => {
        if (!selectedLink.value) return;
        
        isProcessing.value = true;
        loadingMessage.value = 'Processing trend workflow...';
        taskProgress.value = 0;
        results.value = null;
        saveStatus.value = '';
        statusMessage.value = '';
        
        try {
            console.log('Starting trend workflow for URL:', selectedLink.value);
            
            // Update progress for starting
            loadingMessage.value = 'Initializing trend analysis...';
            taskProgress.value = 20;

            const response = await axios.post('/app/scan_trends_admin', {
                url: selectedLink.value
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                }
            });
            
            // Update progress for processing
            loadingMessage.value = 'Processing analysis results...';
            taskProgress.value = 60;
            
            console.log('Received response:', response.data);
            
            // Update results first to trigger the display
            results.value = response.data;
            
            // Parse the response into different sections
            const data = response.data.results?.[0] || {};
            if (data.analyses) {
                referenceTrends.value = data.analyses.reference || [];
                styleTrends.value = data.analyses.style || [];
                colorAnalysis.value = data.analyses.color || [];
                objectDetection.value = data.analyses.object || [];
                moodAnalysis.value = data.analyses.mood || [];
                
                // Update progress for completion
                loadingMessage.value = 'Finalizing results...';
                taskProgress.value = 90;
            } else {
                console.warn('No analyses data in response');
            }
            
            saveStatus.value = 'success';
            statusMessage.value = 'Trends analysis completed successfully';
            
            // Fetch latest trends
            await fetchLatestTrends();
            
            // Complete the progress
            taskProgress.value = 100;
            loadingMessage.value = 'Analysis complete!';
            
        } catch (error) {
            console.error('Error in trend workflow:', error);
            saveStatus.value = 'error';
            statusMessage.value = `Error: ${error.response?.data?.error || error.message}`;
            loadingMessage.value = 'Error occurred during analysis';
        } finally {
            setTimeout(() => {
                isProcessing.value = false;
                loadingMessage.value = '';
                taskProgress.value = 0;
            }, 1000); // Give user time to see completion
        }
    };

    // Add fetchLatestTrends function
    const latestTrends = ref([]);
    
    const formatDate = (date) => {
        return new Date(date).toLocaleDateString();
    };

    const fetchLatestTrends = async () => {
        try {
            const response = await axios.get('/app/trends/latest', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                }
            });
            latestTrends.value = response.data || [];
        } catch (error) {
            console.error('Error fetching latest trends:', error);
        }
    };

    const fetchUserDetails = async () => {
      try {
        loading.value = true;
        const token = localStorage.getItem('userToken');
        if (!token) {
          router.push('/login');
          return;
        }

        const response = await axios.get('/api/user/profile', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.data) {
          user.value = response.data;
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        if (error.response?.status === 401) {
          router.push('/login');
        }
      } finally {
        loading.value = false;
      }
    };

    // Initialize component
    onMounted(async () => {
      await fetchUserDetails();
      await fetchLatestTrends();
    });

    return {
      links,
      selectedLink,
      isProcessing,
      results,
      startTrendWorkflow,
      user,
      loading,
      handleLogout,
      saveStatus,
      statusMessage,
      referenceTrends,
      styleTrends,
      colorAnalysis,
      objectDetection,
      moodAnalysis,
      latestTrends,
      formatDate,
      getProfilePicture,
      handleProfilePicError
    };
  }
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  text-align: center;
}

.header {
  background: white;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 2rem;
  transition: transform 0.3s ease;
}

.header.hidden {
  transform: translateY(-100%);
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-info img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.logout-btn {
  padding: 0.5rem 1rem;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-btn:hover {
  background: #c82333;
}

.initial-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 1000;
}

/* Rest of your existing styles... */
</style>

