<template>
  <aside class="sidebar">
    <a href="/dashboard-user" class="sidebar-link">
      <img src="../assets/images/home_th.svg" class="home-link" alt="Home">
    </a>
    <a href="/voice" class="sidebar-link">
      <img src="../assets/images/voice_th.svg" class="voice-link" alt="Voice">
    </a>
    <a href="/vision" class="sidebar-link">
      <img src="../assets/images/vision_th.svg" class="vision-link" alt="Vision">
    </a>
    <a href="/dashboard-trends" class="sidebar-link">
      <img src="../assets/images/trends_th.svg" class="trends-link" alt="Trends">
    </a>
    <a href="/advice" class="sidebar-link">
      <img src="../assets/images/advice_th.svg" class="advice-link" alt="Advice">
    </a>
    <a href="/settings" class="sidebar-link">
      <img src="../assets/images/settings_th.svg" class="settings-link" alt="Settings">
    </a>
    <a v-if="isAdmin" href="/admin-workflows" class="sidebar-link">
      <img src="../assets/images/admin_icon.svg" class="admin-link" alt="Admin">
    </a>
  </aside>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SideBar',
  data() {
    return {
      isAdmin: false
    };
  },
  async created() {
    try {
      const token = localStorage.getItem('userToken');
      if (token) {
        const response = await axios.get('/app/user-details');
        this.isAdmin = response.data.role === 'admin';
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }
};
</script>

