<template>
  <div id="dashboard-trends">
    <!-- Single Loading overlay -->
    <div v-if="loading || isSubmitting" class="loading-overlay">
      <div class="loading-content">
        <LoadingWheel 
          :status="loadingMessage || 'Loading dashboard...'" 
          :progress="taskProgress" 
        />
      </div>
    </div>

    <!-- Main Content -->
    <template v-if="!loading">
      <!-- Sidebar -->
      <SideBar />

      <!-- Header Section -->
      <header class="header" :class="{ 'hidden': !isHeaderVisible }">
        <div class="header-top">
          <h1>CP3 Dashboard 
            <span v-if="user?.username" class="analyzing-url">
              {{ user.username }}
            </span>
          </h1>
        
          <div class="trend-count">
            <span>{{ marketTrends.length }} MARKET TRENDS</span>
            <span>{{ fashionTrends.length }} FASHION TRENDS</span>
          </div>
        </div>
        <div class="user-info">
          <img 
            :src="getProfilePicture" 
            :alt="user?.instagram_handle + ' profile picture'" 
            @error="handleProfilePicError"
            crossorigin="anonymous"
          />
          <button @click="handleLogout" class="logout-btn">Logout</button>
        </div>
      </header>

      <!-- URL Input Section -->
      <div class="url-input-section">
        <div class="input-wrapper">
          <input 
            v-model="url" 
            type="text" 
            placeholder="Enter Instagram URL to analyze"
            @keyup.enter="analyzeUrl"
          />
          <button 
            @click="analyzeUrl" 
            :disabled="isSubmitting"
            class="analyze-btn"
          >
            Analyze
          </button>
        </div>
        <div v-if="error" class="error-message">{{ error }}</div>
      </div>

      <!-- Main Content -->
      <main class="main-content">
        <div class="analysis-grid">
          <!-- Object Analysis Section -->
          <section class="analysis-card">
            <h2>Object Trends</h2>
            <div class="analysis-content" v-if="analyses.object">
              <p>{{ analyses.object }}</p>
            </div>
            <div v-else class="placeholder">
              Enter a URL above to analyze object trends
            </div>
          </section>

          <!-- Color Analysis Section -->
          <section class="analysis-card color-analysis">
            <h2>Color Trends</h2>
            <div v-if="colorTrends" class="color-palette">
              <div v-for="(color, index) in colorTrends.dominant_colors" 
                   :key="index" 
                   class="color-item"
                   :style="{ backgroundColor: color.color }">
                <div class="color-info">
                  <span class="color-hex">{{ color.color }}</span>
                  <span class="color-percentage">{{ Math.round(color.percentage) }}%</span>
                </div>
              </div>
            </div>
            <div v-else class="placeholder">
              Loading color trends...
            </div>
          </section>

          <!-- Style Analysis Section -->
          <section class="analysis-card">
            <h2>Style Trends</h2>
            <div class="analysis-content" v-if="analyses.style">
              <p>{{ analyses.style }}</p>
            </div>
            <div v-else class="placeholder">
              Enter a URL above to analyze style trends
            </div>
          </section>

          <!-- Mood Analysis Section -->
          <section class="analysis-card">
            <h2>Mood Trends</h2>
            <div class="analysis-content" v-if="analyses.mood">
              <p>{{ analyses.mood }}</p>
            </div>
            <div v-else class="placeholder">
              Enter a URL above to analyze mood trends
            </div>
          </section>
        </div>

        <!-- Storyboard Section -->
        <section class="storyboard-section">
          <h2>Marketing Storyboard</h2>
          <div class="storyboard-content" v-if="storyboard">
            <div class="storyboard-grid">
              <div class="storyboard-card strategy">
                <h3>Strategy Overview</h3>
                <p>{{ storyboard.strategy }}</p>
              </div>
              <div class="storyboard-card audience">
                <h3>Target Audience</h3>
                <p>{{ storyboard.audience }}</p>
              </div>
              <div class="storyboard-card messages">
                <h3>Key Messages</h3>
                <ul>
                  <li v-for="(message, index) in storyboard.messages" :key="index">
                    {{ message }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-else class="placeholder">
            <p>Complete the trend analysis to generate a marketing storyboard</p>
            <button 
              @click="generateStoryboard" 
              :disabled="!analyses.object || isGeneratingStoryboard"
              class="generate-storyboard-btn"
            >
              {{ isGeneratingStoryboard ? 'Generating...' : 'Generate Storyboard' }}
            </button>
          </div>
        </section>

        <!-- Trends Section -->
        <section class="analysis-section">
          <h2>Trends</h2>
          <div class="trends-section">
            <div class="trends-column" v-for="(trends, category) in trendCategories" :key="category">
              <h2>{{ category }}</h2>
              <div v-for="trend in trends" :key="trend.name" class="trend-item">
                <img :src="trend.image" :alt="trend.name" />
                <div>
                  <span class="trend-name">{{ trend.name }}</span>
                  <span class="trend-metrics">{{ trend.metrics }}</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Trend Analysis Section -->
        <section class="analysis-section">
          <h2>Trend Analysis</h2>
          <div class="trend-analysis">
            <h3>Trend Analysis</h3>
            <GrafanaChart :type="'line'" :data="trendData" />
          </div>
        </section>

        <!-- Trend Proportions Section -->
        <section class="analysis-section">
          <h2>Trend Proportions</h2>
          <div class="trend-proportions">
            <h3>Trend Proportions</h3>
            <GrafanaChart :type="'pie'" :data="proportionData" />
          </div>
        </section>
      </main>

      <!-- Token expiration warning -->
      <div v-if="showTokenWarning" class="token-warning">
        <div class="token-warning-content">
          <p>Your session will expire in {{ Math.floor(timeUntilExpiration / 60) }} minutes and {{ timeUntilExpiration % 60 }} seconds.</p>
          <div class="token-warning-actions">
            <button @click="refreshToken" class="refresh-token-btn">Refresh Session</button>
            <button @click="handleLogout" class="logout-btn">Logout</button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import apiConfig from '../config/api.js';
import LoadingWheel from '@/components/LoadingWheel.vue';
import SideBar from '@/components/SideBar.vue';
import GrafanaChart from '../components/GrafanaChart.vue';

export default {
  name: 'DashboardTrends',
  components: {
    LoadingWheel,
    SideBar,
    GrafanaChart
  },
  setup() {
    const router = useRouter();
    const loading = ref(true);
    const isSubmitting = ref(false);
    const loadingMessage = ref('');
    const taskProgress = ref(0);
    const showTokenWarning = ref(false);
    const timeUntilExpiration = ref(0);
    const isHeaderVisible = ref(true);
    const user = ref(null);
    const error = ref('');
    const colorTrends = ref(null);
    const url = ref('');
    const analyses = ref({
      object: null,
      color: null,
      style: null,
      mood: null,
      reference: null
    });
    const storyboard = ref(null);
    const isGeneratingStoryboard = ref(false);

    const getProfilePicture = computed(() => {
      return user.value?.profile_pic || '../assets/images/default-profile.png';
    });

    const handleProfilePicError = (event) => {
      event.target.src = '../assets/images/default-profile.png';
    };

    // Existing data
    const trendCategories = ref({
      'Market Trends': [
        { name: 'Olympics', metrics: '459K +2400%', image: 'olympics.png' },
        { name: 'Paris', metrics: '125K +1300%', image: 'paris.png' },
        { name: 'Community', metrics: '114.5K +5000%', image: 'community.png' }
      ],
      'Fashion Trends': [
        { name: 'Unisex', metrics: '208K +1200%', image: 'unisex.png' },
        { name: 'Gorpcore', metrics: '201K +600%', image: 'gorpcore.png' },
        { name: 'Techwear', metrics: '90K +6200%', image: 'techwear.png' }
      ],
      'Product Trends': [
        { name: 'Trail Vest', metrics: '148K +3600%', image: 'trail_vest.png' },
        { name: 'Gore-Tex', metrics: '201K +490%', image: 'gore_tex.png' },
        { name: 'Running Glasses', metrics: '107K +1200%', image: 'running_glasses.png' }
      ]
    });

    const trendData = ref([
      { 
        name: 'Olympics',
        data: [
          { date: '2024-01-01', value: 100 },
          { date: '2024-01-02', value: 150 },
          { date: '2024-01-03', value: 200 }
        ]
      },
      { 
        name: 'Unisex',
        data: [
          { date: '2024-01-01', value: 80 },
          { date: '2024-01-02', value: 120 },
          { date: '2024-01-03', value: 160 }
        ]
      },
      { 
        name: 'Trail Vest',
        data: [
          { date: '2024-01-01', value: 60 },
          { date: '2024-01-02', value: 90 },
          { date: '2024-01-03', value: 130 }
        ]
      }
    ]);

    const proportionData = ref([
      { name: 'Olympics', value: 0.36 },
      { name: 'Gore-Tex', value: 0.25 },
      { name: 'Trail Vest', value: 0.15 }
    ]);

    const marketTrends = computed(() => trendCategories.value['Market Trends'] || []);
    const fashionTrends = computed(() => trendCategories.value['Fashion Trends'] || []);

    const activeSection = ref('object');

    const handleLogout = async () => {
      // Temporarily disabled for testing
      console.log('Logout clicked');
    };

    const refreshToken = async () => {
      // Temporarily disabled for testing
      console.log('Refresh token clicked');
    };

    const analyzeUrl = async () => {
      if (!url.value) {
        error.value = 'Please enter a URL to analyze';
        return;
      }

      isSubmitting.value = true;
      loadingMessage.value = 'Analyzing trends...';
      error.value = '';
      
      // Development mode: Return mock data
      if (process.env.NODE_ENV === 'development') {
        await new Promise(resolve => setTimeout(resolve, 1500)); // Simulate API delay
        analyses.value = {
          object: "The image shows a strong focus on athletic wear and sports equipment. Key objects include running shoes, performance apparel, and training gear. There's a notable emphasis on technical fabrics and innovative design features.",
          style: "The style is modern and performance-oriented, with a clean, minimalist aesthetic. The color palette is bold yet professional, combining traditional athletic wear colors with contemporary design elements.",
          mood: "The mood conveys energy, determination, and athletic excellence. There's a strong sense of motivation and professional achievement, appealing to both serious athletes and fitness enthusiasts.",
          color: "Dominant colors include deep blues, energetic reds, and neutral grays, creating a balanced and professional sports-oriented aesthetic.",
          reference: "The visual references align with current trends in professional sports and athletic wear, particularly in the running and training categories."
        };
        
        storyboard.value = {
          strategy: "Focus on performance, innovation, and professional athletic excellence. Position the brand as a leader in technical athletic wear with a modern, sophisticated edge.",
          audience: "Primary: Professional athletes and serious sports enthusiasts. Secondary: Fitness-conscious consumers who value high-performance gear and modern design.",
          messages: [
            "Cutting-edge performance technology meets contemporary design",
            "Engineered for athletic excellence and professional results",
            "Premium quality that meets the demands of serious athletes",
            "Innovation in every detail, from materials to design"
          ]
        };
        isSubmitting.value = false;
        loadingMessage.value = '';
        return;
      }

      // Production mode: Normal API call
      try {
        const response = await axios.post('/api/trends/analyze_all', {
          url: url.value.startsWith('http') ? url.value : 'https://' + url.value
        });

        if (response.data.analyses) {
          analyses.value = response.data.analyses;
        }
        if (response.data.storyboard) {
          storyboard.value = response.data.storyboard;
        }
      } catch (error) {
        console.error('Error analyzing trends:', error);
        error.value = error.response?.data?.error || 'Failed to analyze trends. Please try again.';
      } finally {
        isSubmitting.value = false;
        loadingMessage.value = '';
      }
    };

    const fetchColorTrends = async () => {
      // Development mode: Return mock data
      if (process.env.NODE_ENV === 'development') {
        colorTrends.value = {
          dominant_colors: [
            { color: '#FF5733', percentage: 35 },
            { color: '#33FF57', percentage: 25 },
            { color: '#3357FF', percentage: 20 },
            { color: '#F3FF33', percentage: 15 },
            { color: '#FF33F3', percentage: 5 }
          ]
        };
        return;
      }

      // Production mode: Normal API call
      try {
        const response = await axios.get('/api/trends/color-analysis');
        colorTrends.value = response.data;
      } catch (error) {
        console.error('Error fetching color trends:', error);
      }
    };

    const fetchUserDetails = async () => {
      // Development mode: Return mock data
      if (process.env.NODE_ENV === 'development') {
        // Mock user data for development
        user.value = {
          id: 1,
          username: 'DevUser',
          email: 'dev@example.com',
          instagram_handle: 'dev_user',
          profile_pic: 'https://via.placeholder.com/150',
          subscription_status: 'active',
          role: 'admin'
        };
        localStorage.setItem('subscriptionStatus', 'active');
        return;
      }

      // Production mode: Normal authentication flow
      const token = localStorage.getItem('userToken');
      if (!token) {
        router.push('/user-login');
        return;
      }

      try {
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        };

        const response = await axios.get(`${apiConfig.baseURL}/app/user-details`, { headers });
        if (response.data) {
          user.value = response.data;
          localStorage.setItem('subscriptionStatus', response.data.subscription_status);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        if (error.response?.status === 401) {
          localStorage.removeItem('userToken');
          localStorage.removeItem('subscriptionStatus');
          router.push('/user-login');
        }
      }
    };

    const generateStoryboard = async () => {
      if (!analyses.value.object) {
        error.value = 'Please analyze the object trends first';
        return;
      }

      isGeneratingStoryboard.value = true;
      loadingMessage.value = 'Generating storyboard with AI...';
      error.value = '';
      
      // Development mode: Return mock data
      if (process.env.NODE_ENV === 'development') {
        await new Promise(resolve => setTimeout(resolve, 1500)); // Simulate API delay
        storyboard.value = {
          strategy: "Focus on performance, innovation, and professional athletic excellence. Position the brand as a leader in technical athletic wear with a modern, sophisticated edge.",
          audience: "Primary: Professional athletes and serious sports enthusiasts. Secondary: Fitness-conscious consumers who value high-performance gear and modern design.",
          messages: [
            "Cutting-edge performance technology meets contemporary design",
            "Engineered for athletic excellence and professional results",
            "Premium quality that meets the demands of serious athletes",
            "Innovation in every detail, from materials to design"
          ]
        };
        isGeneratingStoryboard.value = false;
        loadingMessage.value = '';
        return;
      }

      // Production mode: Normal API call
      try {
        // First get the brand context
        const brandResponse = await axios.get(`${apiConfig.baseURL}/api/trends/brand-context`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json'
          }
        });

        // Then generate storyboard with brand context and analysis
        const response = await axios.post(`${apiConfig.baseURL}/api/trends/generate_storyboard`, {
          brand_context: brandResponse.data.brand_context,
          analysis: {
            object: analyses.value.object,
            style: analyses.value.style,
            mood: analyses.value.mood,
            color: analyses.value.color,
            reference: analyses.value.reference
          }
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.data.storyboard) {
          storyboard.value = response.data.storyboard;
        } else {
          throw new Error('No storyboard data received from AI');
        }
      } catch (error) {
        console.error('Error generating storyboard:', error);
        error.value = error.response?.data?.error || 'Failed to generate storyboard. Please try again.';
      } finally {
        isGeneratingStoryboard.value = false;
        loadingMessage.value = '';
      }
    };

    // Initialize component
    onMounted(() => {
      Promise.all([
        fetchUserDetails(),
        fetchColorTrends()
      ]).finally(() => {
        loading.value = false;
      });
    });

    return {
      loading,
      isSubmitting,
      loadingMessage,
      taskProgress,
      showTokenWarning,
      timeUntilExpiration,
      isHeaderVisible,
      user,
      getProfilePicture,
      handleProfilePicError,
      trendCategories,
      trendData,
      proportionData,
      marketTrends,
      fashionTrends,
      handleLogout,
      refreshToken,
      activeSection,
      analyses,
      storyboard,
      analyzeUrl,
      url,
      error,
      colorTrends,
      isGeneratingStoryboard,
      generateStoryboard
    };
  }
};
</script>

<style>
.placeholder {
  color: #666;
  font-style: italic;
  text-align: center;
  padding: 20px;
}

.generate-storyboard-btn {
  margin-top: 15px;
  padding: 12px 24px;
  background: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.generate-storyboard-btn:hover:not(:disabled) {
  background: #218838;
}

.generate-storyboard-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.storyboard-section {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.storyboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.storyboard-card {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 6px;
  border-left: 4px solid #007bff;
}

.storyboard-card h3 {
  color: #343a40;
  margin-bottom: 10px;
}

.storyboard-card.strategy {
  border-left-color: #28a745;
}

.storyboard-card.audience {
  border-left-color: #fd7e14;
}

.storyboard-card.messages {
  border-left-color: #007bff;
}

.storyboard-card ul {
  list-style-type: none;
  padding: 0;
}

.storyboard-card li {
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}

.storyboard-card li:before {
  content: "•";
  color: #007bff;
  position: absolute;
  left: 0;
}
</style>

