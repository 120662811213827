<template>
  <div class="grafana-chart">
    <div class="chart-placeholder">
      {{ type }} Chart
      <div class="data-info" v-if="data">
        {{ data.length }} data points
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GrafanaChart',
  props: {
    type: {
      type: String,
      required: true,
      validator: value => ['line', 'pie'].includes(value)
    },
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.grafana-chart {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-placeholder {
  text-align: center;
  color: #666;
}

.data-info {
  font-size: 0.9em;
  margin-top: 8px;
  color: #999;
}
</style>
